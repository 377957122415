import React from "react";
import { NavLink } from 'react-router-dom'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../assets/css/vendor/jquery-ui.min.css'
import '../../assets/css/vendor/fontawesome.css'
import '../../assets/css/vendor/ionicons.min.css'
import '../../assets/css/vendor/bootstrap.min.css'
import '../../assets/css/main.css'

import productImg1 from '../../assets/img/pageImage/cctv-camera-2.jpg'
import productImg2 from '../../assets/img/pageImage/cctv-camera-4.jpg'
import productImg3 from '../../assets/img/pageImage/server-manage.jpg'
import productImg4 from '../../assets/img/pageImage/cctv-camera-7.jpg'
import productImg5 from '../../assets/img/pageImage/cctv-camera-8.jpg'
import productImg6 from '../../assets/img/pageImage/cctv-camera-10.jpg'
import productImg7 from '../../assets/img/pageImage/cctv-wires.jpg'
import productImg8 from '../../assets/img/pageImage/cameras.jpg'
import productImg9 from '../../assets/img/pageImage/hardware-solution-2.jpg'

import LatestProduct from "./LatestProductSection";
import { CardGroup, Carousel } from "react-bootstrap";


const Latest = () => {
    return (
        <>
            <div class="blog gray-bg p-tb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            {/* Start Section Title */}
                            <div class="section-content m-b-35 text-center">
                                <h5 class="section-content__title">Some <span className="white-orange">PRODUCT</span> </h5>
                                <a href="blog-list-sidebar-left.html" class="section-content__desc"></a>
                            </div>
                            {/* End Section Title */}
                        </div>
                    </div>


                    <div className="row mx-auto">
                        <Carousel>
                            <Carousel.Item >
                                <CardGroup className="mx-auto">
                                    <div className="col-md-4 col-12">
                                        <LatestProduct cardImg={productImg1} cardHeading="CCTV Camear" cardContent="Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus. Praesent" cardLink="Read More" />
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <LatestProduct cardImg={productImg2} cardHeading="Street Camera" cardContent="Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus. Praesent" cardLink="Read More" />
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <LatestProduct cardImg={productImg3} cardHeading="Server Managment" cardContent="Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus. Praesent" cardLink="Read More" />
                                    </div>
                                </CardGroup>
                            </Carousel.Item>
                            <Carousel.Item >
                                <CardGroup className="mx-auto">
                                    <div className="col-md-4 col-12">
                                        <LatestProduct cardImg={productImg4} cardHeading="Wall Mini Camra" cardContent="Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus. Praesent" cardLink="Read More" />
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <LatestProduct cardImg={productImg5} cardHeading="Building Security" cardContent="Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus. Praesent" cardLink="Read More" />
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <LatestProduct cardImg={productImg6} cardHeading="SPY Camera" cardContent="Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus. Praesent" cardLink="Read More" />
                                    </div>
                                </CardGroup>
                            </Carousel.Item>
                            <Carousel.Item >
                                <CardGroup className="mx-auto">
                                    <div className="col-md-4 col-12">
                                        <LatestProduct cardImg={productImg7} cardHeading="cctv-Connection" cardContent="Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus. Praesent" cardLink="Read More" />
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <LatestProduct cardImg={productImg8} cardHeading="Wall Camera" cardContent="Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus. Praesent" cardLink="Read More" />
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <LatestProduct cardImg={productImg9} cardHeading="Hardware Solution" cardContent="Donec vitae hendrerit arcu, sit amet faucibus nisl. Cras pretium arcu ex. Aenean posuere libero eu augue condimentum rhoncus. Praesent" cardLink="Read More" />
                                    </div>
                                </CardGroup>
                            </Carousel.Item>

                        </Carousel>
                    </div>

                </div>
            </div>

            {/* ::::::  End  Blog News   ::::::  */}
        </>
    )
};

export default Latest;