import React from 'react'
import Footer from '../../Footer'
import Navbar from '../../Navbar'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import '../src/assets/css/vendor/jquery-ui.min.css'
// import '../src/assets/css/vendor/fontawesome.css'
// import '../src/assets/css/vendor/ionicons.min.css'
// import '../src/assets/css/vendor/bootstrap.min.css'
import '../../assets/css/main.css'


import AboutImg1 from '../../assets/img/pageImage/cctv-camera-2.jpg'
import AboutImg2 from '../../assets/img/pageImage/hardware-solution-2.jpg'
import AboutImg3 from '../../assets/img/pageImage/telicommunication.jpg'
import AboutImg4 from '../../assets/img/pageImage/cctv-wires-5.jpg'
import AboutImg5 from '../../assets/img/pageImage/buessness-frendly-1.jpg'
import AboutBuessness from '../../assets/img/pageImage/Buessness-support.jpg'

import csm1 from '../../assets/img/icon/cms/icon_about1.jpg'
import csm2 from '../../assets/img/icon/cms/icon_about2.jpg'
import csm3 from '../../assets/img/icon/cms/icon_about3.jpg'
import csm4 from '../../assets/img/icon/cms/icon_about4.jpg'

import leader1 from '../../assets/img/team/leader1.png'
import leader2 from '../../assets/img/team/leader2.png'
import leader3 from '../../assets/img/team/leader3.png'
import leader4 from '../../assets/img/team/leader4.png'
import MiddleBannerImg1 from '../../assets/img/banner/size-extra-large-wide/banner-home-1-img-1-extra-large-wide.jpg'



import ServiceCard, { AboutContent, CompanyLogos, TeamLeader } from './AboutSection'
import CompanyLogo from '../company/CompanyLogo'
import { MiddleBanner } from '../Banner/Banner'
import whatsappLogo from '../../assets/img/whatsapp.png';


export default function About() {
    return (
        <>
            <Navbar />

            <MiddleBanner MiddleBannerImg={MiddleBannerImg1} BannerHeading="About Us" />
            <div className='my-5'>
                <div class="about-top">

                    < AboutContent AboutImg1={AboutBuessness} AboutImgHeader1="Buessness " AboutImgHeader2="friendly"
                        AboutImgInfo1="Infotec provide you with the latest and most advanced electronic hardware products. Our mission is to offer our customers the highest quality products and exceptional customer service."
                        AboutImgInfo2="Welcome to our hardware electronic shop, where we provide you with the latest and most advanced electronic hardware products. Our mission is to offer our customers the highest quality products and exceptional customer service."
                        AboutImgInfo3="Our shop has been in business for several years, and we have become a trusted source for all things related to hardware electronics. We have a team of experienced professionals who are passionate about technology and are always up-to-date with the latest trends in the industry."
                        AboutImgInfo4="At our shop, you will find a wide range of products, including computer hardware, mobile phones, cameras, and other electronic devices. We carefully select our products from reputable manufacturers to ensure that they meet our high standards of quality."
                        AboutImgInfo5="We understand that buying electronic hardware products can be a daunting task, which is why we are committed to providing our customers with a hassle-free shopping experience. Our friendly and knowledgeable staff are always on hand to help you with any questions or concerns you may have about our products."
                        AboutImgInfo6="We also offer competitive pricing and special promotions to help you save money on your purchases. Our goal is to make sure that you not only get the best products but also the best value for your money."
                        AboutImgInfo7="Thank you for considering our hardware electronic shop for your electronic hardware needs. We look forward to serving you and providing you with the best products and services in the industry."
                    />


                    {/* < AboutContent AboutImg1={AboutImg1} AboutImgHeader1="CCTV & Security" AboutImgContent1="Welcome to our hardware electronic shop, where we provide you with
                        the latest and most advanced electronic hardware products" AboutImgInfo1="Our shop has been in business for several years, and we have become a trusted source
                        for all things related to hardware electronics. We have a team of experienced professionals who are passionate about technology and are always up-to-date with
                        the latest trends in the industry. Our specialist won't be afraid to go extra miles just to approach near perfection. We don't require everything to be perfect,
                        but we need them to be perfectly cared for. That's a reason why we are willing to give contributions at best. Not a single detail is missed out under Billey's
                         professional eyes. The amount of dedication and effort equals to the level of passion and determination. Get better, together as one."

                        AboutImg2={AboutImg2} AboutImgHeader2="Mobile Network Booster" AboutImgContent2="We believe that every project existing in digital world is a result of an idea and every idea has a cause."
                        AboutImgInfo2="For this reason, our each design serves an idea. Our strength in design is reflected by our name, our care for details.
                        Our specialist won't be afraid to go extra miles just to approach near perfection. We don't require everything to be perfect, but we need
                        them to be perfectly cared for. That's a reason why we are willing to give contributions at best. Not a single detail is missed out under Billey's
                        professional eyes.The amount of dedication and effort equals to the level of passion and determination. Get better, together as one."
                    /> */}

                    {/* < AboutContent AboutImg1={AboutImg3} AboutImgHeader1="TeliCommunication & PBX" AboutImgContent1="Used as a business telephone system or private telephone network,
                        a PBX is owned and operated by the enterprise rather than the telephone company - the telephone company of which may be considered a supplier or service provider,"
                        AboutImgInfo1="With the support of our team of skilled professionals, we offer wide assortment EPABX System to our clients. EPABX is essentially required for
                        all business, institutions and professionals. The set up could be a small office, home office or a mobile business. For a convergent enterprise, our clients can
                        avail EPABX or a communication server from assortment of our products matching your exact needs and plans. Features: Multi-purpose Highly functional Easy to operate."

                        AboutImg2={AboutImg4} AboutImgHeader2="Network Solutions" AboutImgContent2="We carefully select our products from reputable manufacturers to ensure that they meet our high standards of quality."
                        AboutImgInfo2="For this reason, our each design serves an idea. Our strength in design is reflected by our name, our care for details. Our specialist won't be afraid to go extra miles just to approach near 
                        perfection. We don't require everything to be perfect, but we need them to be perfectly cared for. That's a reason why we are willing to give contributions at best. Not a single detail is missed out under 
                        Billey's professional eyes.The amount of dedication and effort equals to the level of passion and determination. Get better, together as one."
                    /> */}

                </div>

                {/* <!-- ::::::  Start  Proogress Section  ::::::  --> */}
                <div class="gray-bg p-tb-100 my-2">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <h4 class="font--semi-bold">Public Satisfaction Review</h4>
                                <div class="para__content">
                                    <p class="para__text">Our Highly satisfied clients speak our success stories over the years
                                        and our highly proficient team of experts always ensures to deliver only the best to our clients.
                                        Apart from this we have also expanded our wings in the Electronic marketing and Hardware Solution since the past few years.
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="custom-progress">
                                    <div class=" skill-progressbar">
                                        <h6 class="font--semi-bold ">Hardware & Software</h6>
                                        <div class="progress">
                                            <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" style={{ width: "90%" }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">90%</div>
                                        </div>
                                    </div>
                                    <div class="skill-progressbar">
                                        <h6 class="font--semi-bold ">Ideas</h6>
                                        <div class="progress">
                                            <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" style={{ width: "80%" }} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">80% </div>
                                        </div>
                                    </div>
                                    <div class="skill-progressbar">
                                        <h6 class="font--semi-bold ">CCTV & Security</h6>
                                        <div class="progress">
                                            <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" style={{ width: "95%" }} aria-valuenow="95" aria-valuemin="0" aria-valuemax="100">95%</div>
                                        </div>
                                    </div>
                                    <div class="skill-progressbar">
                                        <h6 class="font--semi-bold ">Telicommunication</h6>
                                        <div class="progress">
                                            <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" style={{ width: "90%" }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">90%</div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- ::::::  End Progress Section  ::::::  --> */}

                <div class="container">
                    <h1 className='section-content__title text-center mt-5'>OUR <span className='white-orange'> MISSION</span> & VISSION</h1>
                    <div class="row container mb-5 d-flex">
                        <div class="col-lg-3 col-sm-6 col-12">
                            <ServiceCard cardImg={csm1} cardTittle="Creative Always" cardDescription="Stay creative with Billey and the huge collection of premade elements, layouts & effects." />
                        </div>

                        <div class="col-lg-3 col-sm-6 col-12">
                            <ServiceCard cardImg={csm2} cardTittle="EXPRESS CUSTOMIZATION" cardDescription="Stay creative with Billey and the huge collection of premade elements, layouts & effects." />
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <ServiceCard cardImg={csm3} cardTittle="PREMIUM INTEGRATIONS" cardDescription="Stay creative with Billey and the huge collection of premade elements, layouts & effects." />
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <ServiceCard cardImg={csm4} cardTittle="REAL-TIME EDITING" cardDescription="Stay creative with Billey and the huge collection of premade elements, layouts & effects." />
                        </div>
                    </div>
                </div>

                {/* ----- new section leader------ */}


                {/* ----- team leader ----- */}

                {/* <div class="py-5 gray-bg">
                    <div class="container py-2">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="section-content text-center">
                                    <h5 class="section-content__title">Meet Our Leaders</h5>
                                </div>
                            </div>
                            <div class="col-10 pt-4 mx-auto">
                                <div class="row inner-slider inner-slider-grid-4 d-flex justify-content-between ">
                                    <TeamLeader LeaderImg={leader1} LeaderName="Ms. Veronica" LeaderTitle="Web Designer" />
                                    <TeamLeader LeaderImg={leader2} LeaderName="Ms. Veronica" LeaderTitle="Web Designer" />
                                    <TeamLeader LeaderImg={leader3} LeaderName="Ms. Veronica" LeaderTitle="Web Designer" />
                                    <TeamLeader LeaderImg={leader4} LeaderName="Ms. Veronica" LeaderTitle="Web Designer" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* end of teame leader */}
            </div>

            {/* -------- company logo ---------- */}

            {/* <div class="company-logo py-4 mt-2">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div className="company-logo__area d-flex justify-content-between">
                                <div class="company-logo__item">
                                    <a href="#" className="d-flex company-logo__link">
                                        <CompanyLogo />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <CompanyLogo /> */}

            <Footer />
            <div class="fixed-bottom right-100 p-3" style={{ zIndex: "6", left: "initial" }}>
                <a href="https://wa.me/919004392220?text=Hello How can I help you ?" target="_blank">
                    <img src={whatsappLogo} width="60" alt="aaaa" />
                </a>
            </div>
        </>
    )
}
