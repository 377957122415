import React from "react";
import Cms1 from '../../assets/img/icon/cms/icon1.png';
import Cms2 from '../../assets/img/icon/cms/icon2.png';
import Cms3 from '../../assets/img/icon/cms/icon3.png';
import Cms4 from '../../assets/img/icon/cms/icon4.png';



const Cms = () => {
    return (
        <>
            {/* <!-- ::::::  Start CMS Section  ::::::  --> */}
            <div class="cms cms--1 m-t-100">
                <div class="container">
                    <div class="row">
                        {/* <!-- Start Single CMS box --> */}
                        <div class="col-lg-3 col-sm-6 col-12">
                            <div class="cms__content">
                                <div class="cms__icon">
                                    {/* <img class="cms__icon-img" src="assets/img/icon/cms/icon1.png" alt="" /> */}
                                    <img src={Cms1} alt="" class="company-logo__img" />
                                </div>
                                <div class="cms__text">
                                    <h6 class="cms__title">FREE SHIPPING</h6>
                                    <span class="cms__des">Get 10% cash back, free shipping, free returns, and more at 1000+ top retailers!</span>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Single CMS box --> */}
                        {/* <!-- Start Single CMS box --> */}
                        <div class="col-lg-3 col-sm-6 col-12">
                            <div class="cms__content">
                                <div class="cms__icon">
                                    <img src={Cms2} alt="" class="company-logo__img" />                                </div>
                                <div class="cms__text">
                                    <h6 class="cms__title">30 DAYS MONEY BACK</h6>
                                    <span class="cms__des">100% satisfaction guaranteed, or get your money back within 30 days!!</span>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Single CMS box --> */}
                        {/* <!-- Start Single CMS box --> */}
                        <div class="col-lg-3 col-sm-6 col-12">
                            <div class="cms__content">
                                <div class="cms__icon">
                                    <img src={Cms3} alt="" class="company-logo__img" />                                </div>
                                <div class="cms__text">
                                    <h6 class="cms__title">SAFE PAYMENT</h6>
                                    <span class="cms__des">Pay with the world’s most popular and secure payment methods.</span>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Single CMS box --> */}
                        {/* <!-- Start Single CMS box --> */}
                        <div class="col-lg-3 col-sm-6 col-12">
                            <div class="cms__content">
                                <div class="cms__icon">
                                    <img src={Cms4} alt="" class="company-logo__img" />                                </div>
                                <div class="cms__text">
                                    <h6 class="cms__title">LOYALTY CUSTOMER</h6>
                                    <span class="cms__des">Card for the other 30% of their purchases at a rate of 1% cash back.</span>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Single CMS box --> */}
                    </div>
                </div>
            </div>
            {/* <!-- ::::::  End CMS Section  ::::::  --> */}
           
        </>
    )
};

export default Cms;