

import React from "react";
import { MiddleBanner } from "../Banner/Banner";
import MiddleBannerImg1 from '../../assets/img/banner/size-extra-large-wide/banner-home-1-img-1-extra-large-wide.jpg'
import Navbar from "../../Navbar";
import ProductDetails from "./ProductDetails";
import Footer from "../../Footer";
import whatsappLogo from '../../assets/img/whatsapp.png';


import ServicesProduct1 from '../../assets/img/Services/Video Conferencing System/product-gallery-large-1.jpg';
import ServicesProduct2 from '../../assets/img/Services/Video Conferencing System/product-gallery-large-2.jpg';
import ServicesProduct3 from '../../assets/img/Services/Video Conferencing System/product-gallery-large-3.jpg';
import ServicesProduct4 from '../../assets/img/Services/Video Conferencing System/product-gallery-large-4.jpg';

const VideoConferencingSystem = () => {
    return (
        <>
            <Navbar />
            <MiddleBanner MiddleBannerImg={MiddleBannerImg1} BannerHeading="Video Conferencing" />

            <div className="product-details mt-5">
                <div class="container">
                    <div class="row mt-5 pt-4">
                        <div class="col-md-5">
                            <div class="product-gallery-box m-b-60">
                                <div class="product-image--gallery overflow-hidden">
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct1} alt="" />
                                            {/* <img src="assets/img/product/gallery/gallery-large/product-gallery-large-2.jpg" alt="" /> */}
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct2} alt="" />
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct3} alt="" />
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct4} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-5">
                            <div class="product-details-box m-b-60 px-2">
                                <h5 className="h2 m-b-20">Video Conferencing System</h5>

                                <div class="product__desc m-t-25 m-b-30">
                                    <p className='text-justify'>
                                        Video conferencing systems have revolutionized communication and collaboration by enabling real-time, face-to-face interactions between remote participants. They have become essential tools for businesses, educational institutions, healthcare providers, and individuals seeking efficient and effective remote communication, teamwork, and knowledge sharing.
                                    </p>
                                </div>
                                <div class="product-var p-tb-30">
                                    <div class="product__stock m-b-20">
                                        <span class="product__stock--in"><i class="fas fa-check-circle"></i> IN STOCK</span>
                                    </div>
                                    {/* <div class="product-color product-var__item">
                                        <span class="product-var__text">Color</span>
                                        <div class="sidebar__color-filter ">
                                            <label class="product-color"><input name="large-product-color" type="radio" class="product-color-select" value="color-red" /><span></span></label>
                                            <label class="product-color" ><input name="large-product-color" type="radio" class="product-color-select" value="color-green" checked /><span></span></label>
                                            <label class="product-color" ><input name="large-product-color" type="radio" class="product-color-select" value="color-blue" /><span></span></label>
                                        </div>
                                    </div> */}

                                    {/* <div class="product-links">
                                        <a href="wishlist.html" class="link--gray link--icon-left shop__wishlist-icon m-b-5 m-r-20"><i class="ion-android-favorite-outline"></i>Add To Wishlist</a>
                                        <a href="compare.html" class="link--gray link--icon-left shop__wishlist-icon m-b-5"><i class="ion-ios-loop-strong"></i>Compare</a>
                                    </div> */}
                                    <div className='col-lg-12 col-md-12 d-none d-md-block d-lg-block'>

                                        <div class="nametag p-tb-10 ">
                                            <span className=''>Product:</span>
                                            <ul class="nametag__list d-flex justify-content-between">
                                                <li>ConferenceCams,</li>
                                                <li>webcams,</li>
                                                <li>WebexRoom,</li>
                                                <li>large meeting rooms</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="nametag p-tb-10">
                                        <span>Share This Product :</span>
                                        <ul class="nametag__list d-flex justify-content-center">
                                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                            <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product-details-tab-area">
                <div class="container">
                    <div class="row pb-5">
                        <div class="col-12">
                            <ul class="tablist tablist--style-black tablist--style-title tablist--style-gap-70 nav">
                                <li><a class="nav-link active" data-bs-toggle="tab" href="#product-desc">Description</a></li>
                            </ul>
                        </div>
                        <div class="col-md-12">
                            <div class="product-details-tab-box">
                                <div class="tab-content">
                                    {/* Start Tab - Product Description */}
                                    <div class="tab-pane show active" id="product-desc">
                                        <div class="para__content">
                                            <h6 class="para__title">Video Conferencing System :</h6>
                                            <p class="para__text">
                                                <p className="para__text">A Video Conferencing System refers to a comprehensive set of technologies, strategies, and services designed to address the networking needs of an organization. It involves the implementation, management, and optimization of Video Conferencing System to facilitate efficient and secure communication, data sharing, and resource utilization among devices, systems, and users within the organization.
                                                </p>
                                                <h6 class="para__title">Key components and features of a video conferencing system include :</h6>
                                                <ul class="para__list px-1">
                                                    <li className="py-1">1. Audio and Video Transmission: Video conferencing systems facilitate the transmission of high-quality audio and video streams between participants. They typically include cameras, microphones, and speakers to capture and deliver audiovisual data.</li>
                                                    <li className="py-1">2. Multipoint Capability: Video conferencing systems support multipoint connections, enabling multiple participants to join a video conference simultaneously. This allows for group discussions, team meetings, and presentations involving participants from different locations.</li>
                                                    <li className="py-1">3. Display and Presentation: Video conferencing systems provide display capabilities to show live video feeds of participants and shared content such as presentations, documents, or screens. This allows for visual presentations and collaborative content sharing during meetings.</li>
                                                    <li className="py-1">4. Collaboration Tools: Many video conferencing systems incorporate collaboration features, including screen sharing, whiteboarding, file sharing, and chat functionalities. These tools enhance interaction, facilitate real-time collaboration, and improve productivity during video conferences.</li>
                                                    <li className="py-1">5. Recording and Playback: Video conferencing systems often offer the ability to record video conferences for later playback or sharing with participants who couldn't attend the live session. This feature allows for review, documentation, and reference purposes.</li>
                                                    <li className="py-1">6. Integration with Calendar and Communication Tools: Video conferencing systems may integrate with calendar applications and communication platforms, making it easy to schedule and join video conferences directly from email invitations or collaboration tools. This streamlines the meeting scheduling and joining process.</li>
                                                    <li className="py-1">7. Security and Encryption: Video conferencing systems prioritize data security by implementing encryption protocols and security measures to protect sensitive information shared during conferences. This ensures that conversations and data remain private and secure.</li>
                                                    <li className="py-1">8. Compatibility and Connectivity: Video conferencing systems are designed to be compatible with different devices and platforms, allowing participants to join conferences using desktop computers, laptops, tablets, or smartphones. They also support various network connections, including wired, wireless, and internet-based connections.</li>
                                                    <li className="py-1">9. Scalability: Video conferencing systems can be scaled to accommodate different meeting sizes, from one-on-one conversations to large-scale webinars or conferences. This flexibility allows organizations to adapt the system according to their changing needs.</li>
                                                    <li className="py-1">10. Cloud-Based Solutions: Many video conferencing systems offer cloud-based solutions, where the infrastructure and resources are hosted and managed by a service provider. This provides convenience, flexibility, and scalability while reducing the need for on-premises hardware and maintenance.</li>
                                                </ul>
                                            </p>

                                        </div>
                                        <p className="para__text">Video conferencing systems have revolutionized communication and collaboration by enabling real-time, face-to-face interactions between remote participants. They have become essential tools for businesses, educational institutions, healthcare providers, and individuals seeking efficient and effective remote communication, teamwork, and knowledge sharing.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
            <div class="fixed-bottom right-100 p-3" style={{ zIndex: "6", left: "initial" }}>
                <a href="https://wa.me/919004392220?text=Hello How can I help you ?" target="_blank">
                    <img src={whatsappLogo} width="60" alt="aaaa" />
                </a>
            </div>
        </>
    )
};

export default VideoConferencingSystem;