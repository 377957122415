import React from "react";
import { NavLink } from "react-router-dom";
import Logo1 from './assets/img/logo/logo-color-red.png';
import Logo from './assets/img/logo/logo.png';

const Footer = () => {
    return (
        <>
            {/* <h1>This is footer page</h1> */}
            {/* <!-- ::::::  Start  Footer ::::::  --> */}
            <footer className="footer" style={{}}>
                <div class="container">
                    {/* <!-- Start Footer Top Section --> */}
                    <div class="footer__top">
                        <div class="row">
                            <div class="col-lg-3 col-sm-6 col-12">
                                {/* <!-- Start Footer Nav --> */}
                                <div class="footer__menu">
                                    <div class="footer__nav-title">
                                        <NavLink className="navbar-brand" to="/">
                                            <img src={Logo} style={{ width: "160px" }} alt="" />
                                        </NavLink>
                                    </div>

                                    <div>
                                        <p className="footer__link mt-0">Welcome to our hardware electronic shop, Infotec provide you with the latest and most advanced
                                            electronic hardware products. Our mission is to offer
                                            our customers the highest quality products.
                                        </p>
                                    </div>

                                    {/* <ul class="footer__nav">
                                        <li class="footer__list"><a href="#"
                                            class="footer__link">Delivery
                                            Information</a></li>
                                        <li class="footer__list"><a
                                            href="privacy-policy.html"
                                            class="footer__link">Privacy Policy</a></li>
                                        <li class="footer__list"><a href="#"
                                            class="footer__link">Terms &
                                            Conditions</a></li>
                                        <li class="footer__list"><a
                                            href="contact.html"
                                            class="footer__link">Contact</a></li>
                                        <li class="footer__list"><a href="#"
                                            class="footer__link">Returns</a></li>
                                    </ul> */}
                                </div>
                                {/* <!-- End Footer Nav --> */}
                            </div>

                            <div class="col-lg-2 col-sm-6 col-12">
                                <div class="footer__menu">
                                    <h4 class="footer__nav-title">quick link</h4>
                                    <ul class="footer__nav">
                                        <li class="footer__list"><NavLink to="/" className="footer__link ">Home</NavLink></li>
                                        <li class="footer__list"><NavLink to="/about" className="footer__link ">About</NavLink></li>
                                        <li class="footer__list"><NavLink to="/services" className="footer__link ">Services</NavLink></li>
                                        <li class="footer__list"><NavLink to="/contact" className="footer__link ">Contact Us</NavLink></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-3 col-sm-6 col-12">
                                <div class="footer__menu">
                                    <h4 class="footer__nav-title">Our Services</h4>
                                    <ul class="footer__nav">

                                        <li class="footer__list"><NavLink to="/server-desktop" className="footer__link ">Server & Desktop</NavLink></li>
                                        {/* <li class="footer__list"><NavLink to="/firewall-Software" className="footer__link ">Firewall & Software</NavLink></li> */}
                                        <li class="footer__list"><NavLink to="/network-Solutions" className="footer__link ">Network Solutions</NavLink></li>
                                        <li class="footer__list"><NavLink to="/mobile-network-booster" className="footer__link ">Mobile Network Booster</NavLink></li>
                                        {/* <li class="footer__list"><NavLink to="/teliCommunication" className="footer__link ">TeliCommunication & PBX</NavLink></li> */}
                                        <li class="footer__list"><NavLink to="/video-conferencing" className="footer__link ">Video Conferencing System</NavLink></li>
                                        <li class="footer__list"><NavLink to="/cctv-security" className="footer__link ">CCTV & Security</NavLink></li>
                                        {/* <li class="footer__list"><NavLink to="/fireal-armSystem" className="footer__link ">Fire Alarm System</NavLink></li> */}

                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-3 col-sm-6 col-12">
                                <div class="footer__about">
                                    <div class="footer__logo">
                                        <h2 class="footer__nav-title ">Get in touch</h2>
                                        <ul className="footer__address px-0">
                                            <li class="footer__address-item">Address:
                                                A7,Virat Balaji darshan Patharli Road Gogras Wadi Dombivali East</li>
                                            <li class="footer__address-item">Call Us:
                                                09930377401</li>
                                            <li class="footer__address-item">Email:
                                           sales@infotecsolution.in</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <!-- End Footer Top Section --> */}
                    {/* <!-- Start Footer Bottom Section --> */}
                    <div class="footer__bottom">
                        <div class="row">
                            <div class="col-lg-8 col-md-6 col-12">
                                {/* <!-- Start Footer Copyright Text --> */}
                                <div class="">
                                    <p className="mt-3">Copyright © Infotec, All rights reserved. designed and developed by <a className="aakaarInfotech" href="https://www.aakaarinfotech.com/">Aakaar Infotech</a></p>
                                </div>
                                {/* <!-- End Footer Copyright Text --> */}
                            </div>
                            <div class="col-lg-4 col-md-6 col-12 ">
                                {/* <!-- Start Footer Payment Logo --> */}
                                <div class="footer__payment ">
                                    <a href="#" class="footer__payment-link">
                                        {/* <img src="assets/img/company-logo/payment.png" alt="" class="footer__payment-img" /> */}
                                        <ul class="footer__social-nav p-0">
                                            <li class="footer__social-list"><a href="#"
                                                class="footer__social-link"><i
                                                    class="fab fa-facebook-f"></i></a></li>
                                            <li class="footer__social-list"><a href="#"
                                                class="footer__social-link"><i
                                                    class="fab fa-twitter"></i></a></li>
                                            <li class="footer__social-list"><a href="#"
                                                class="footer__social-link"><i
                                                    class="fab fa-youtube"></i></a></li>
                                            <li class="footer__social-list"><a href="#"
                                                class="footer__social-link"><i
                                                    class="fab fa-google-plus-g"></i></a></li>
                                            <li class="footer__social-list"><a href="#"
                                                class="footer__social-link"><i
                                                    class="fab fa-instagram"></i></a></li>
                                        </ul>
                                    </a>
                                </div>
                                {/* <!-- End Footer Payment Logo --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Footer Bottom Section --> */}
                </div>
            </footer>
            {/* <!-- ::::::  End  Footer ::::::  --> */}
            {/*  */}
        </>
    )
};

export default Footer;