import React from "react";
import { MiddleBanner } from "../Banner/Banner";
import MiddleBannerImg1 from '../../assets/img/banner/size-extra-large-wide/banner-home-1-img-1-extra-large-wide.jpg'
import Navbar from "../../Navbar";
import ProductDetails from "./ProductDetails";
import Footer from "../../Footer";
import whatsappLogo from '../../assets/img/whatsapp.png';

import ServicesProduct1 from '../../assets/img/Services/Firewall And Software/product-gallery-large-1.jpg';
import ServicesProduct2 from '../../assets/img/Services/Firewall And Software/product-gallery-large-2.jpg';
import ServicesProduct3 from '../../assets/img/Services/Firewall And Software/product-gallery-large-3.jpg';
import ServicesProduct4 from '../../assets/img/Services/Firewall And Software/product-gallery-large-4.jpg';

const FirewallAndSoftware = () => {
    return (
        <>

            <Navbar />
            <MiddleBanner MiddleBannerImg={MiddleBannerImg1} BannerHeading="Firewall and Software" />

            <div className="product-details mt-5">
                <div class="container">
                    <div class="row mt-5 pt-4">
                        <div class="col-md-5">
                            <div class="product-gallery-box m-b-60">
                                <div class="product-image--gallery overflow-hidden">
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct1} alt="" />
                                            {/* <img src="assets/img/product/gallery/gallery-large/product-gallery-large-2.jpg" alt="" /> */}
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct2} alt="" />
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct3} alt="" />
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct4} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-5">
                            <div class="product-details-box m-b-60 px-2">
                                <h5 className="h2 m-b-20">Firewall and Software</h5>

                                <div class="product__desc m-t-25 m-b-30">
                                    <p className='text-justify'>A firewall is a security device or software application that acts as a barrier between a trusted internal network and an untrusted external network, such as the internet.
                                        It is designed to monitor and control network traffic, enforcing a set of predefined security rules to protect the internal network from unauthorized access, malicious activities, and potential threats.
                                        <br />Firewalls operate at the network level (network firewall) or the host level (host-based firewall) to filter and inspect incoming and outgoing network traffic. Here is a description of both types:
                                    </p>
                                </div>
                                <div class="product-var p-tb-30">
                                    <div class="product__stock m-b-20">
                                        <span class="product__stock--in"><i class="fas fa-check-circle"></i> IN STOCK</span>
                                    </div>
                                    {/* <div class="product-color product-var__item">
                                        <span class="product-var__text">Color</span>
                                        <div class="sidebar__color-filter ">
                                            <label class="product-color"><input name="large-product-color" type="radio" class="product-color-select" value="color-red" /><span></span></label>
                                            <label class="product-color" ><input name="large-product-color" type="radio" class="product-color-select" value="color-green" checked /><span></span></label>
                                            <label class="product-color" ><input name="large-product-color" type="radio" class="product-color-select" value="color-blue" /><span></span></label>
                                        </div>
                                    </div> */}

                                    {/* <div class="product-links">
                                        <a href="wishlist.html" class="link--gray link--icon-left shop__wishlist-icon m-b-5 m-r-20"><i class="ion-android-favorite-outline"></i>Add To Wishlist</a>
                                        <a href="compare.html" class="link--gray link--icon-left shop__wishlist-icon m-b-5"><i class="ion-ios-loop-strong"></i>Compare</a>
                                    </div> */}
                                    <div className='col-lg-12 col-md-12 d-none d-md-block d-lg-block'>

                                        <div class="nametag p-tb-10 ">
                                            <span className=''>Product:</span>
                                            <ul class="nametag__list d-flex justify-content-between">
                                                <li>packet filtering,</li>
                                                <li>circuit-level gateway,</li>
                                                <li> stateful inspection</li>
                                                <li>firewall.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="nametag p-tb-10">
                                        <span>Share This Product :</span>
                                        <ul class="nametag__list d-flex justify-content-center">
                                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                            <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product-details-tab-area">
                <div class="container">
                    <div class="row pb-5">
                        <div class="col-12">
                            <ul class="tablist tablist--style-black tablist--style-title tablist--style-gap-70 nav">
                                <li><a class="nav-link active" data-bs-toggle="tab" href="#product-desc">Description</a></li>
                            </ul>
                        </div>
                        <div class="col-md-12">
                            <div class="product-details-tab-box">
                                <div class="tab-content">
                                    {/* Start Tab - Product Description */}
                                    <div class="tab-pane show active" id="product-desc">
                                        <div class="para__content">
                                            <h6 class="para__title">Firewall And Software :</h6>
                                            <p class="para__text">
                                                <p className="para__text">A firewall is a security device or software application that acts as a barrier between a trusted internal network and an untrusted external network, such as the internet. It is designed to monitor and control network traffic, enforcing a set of predefined security rules to protect the internal network from unauthorized access, malicious activities, and potential threats.
                                                    <br />Firewalls operate at the network level (network firewall) or the host level (host-based firewall) to filter and inspect incoming and outgoing network traffic. Here is a description of both types:
                                                </p>
                                                <h6 class="para__title">Network Firewall:</h6>
                                                <ul class="para__list p-0">
                                                    <li className="py-1 p-0">A network firewall is typically a hardware device or software application deployed at the network perimeter, such as a router or dedicated firewall appliance. It examines network packets passing through it and applies security policies to allow or block specific types of traffic based on defined rules. Key features of network firewalls include:</li>
                                                    <ul className="py-1 px-1">
                                                        <li>1. Access Control: Network firewalls use access control lists (ACLs) to permit or deny network traffic based on source and destination IP addresses, port numbers, protocols, or other packet attributes. They establish a secure boundary between internal and external networks, allowing authorized traffic and blocking unauthorized access attempts.</li>
                                                        <li>2. Stateful Inspection: Network firewalls employ stateful packet inspection to track the state of network connections and validate the legitimacy of incoming packets. They analyze the entire context of a communication session to identify potential threats or anomalies.</li>
                                                        <li>3. Network Address Translation (NAT): Network firewalls often include NAT capabilities to translate private IP addresses used internally to a public IP address visible on the internet. This helps protect the internal network structure and enhances privacy.</li>
                                                        <li>4. VPN Support: Many network firewalls support Virtual Private Network (VPN) functionality, allowing secure remote access to the internal network for authorized users. VPN tunnels provide encrypted communication and ensure data confidentiality.</li>
                                                    </ul>
                                                </ul>
                                                <h6 class="para__title">Host-Based Firewall::</h6>
                                                <ul className="p-0">
                                                    <li className="p-0">A host-based firewall is a software application installed on individual computers or servers to provide local network security. It operates at the operating system or kernel level, monitoring and controlling network traffic specific to the host machine. Key features of host-based firewalls include:</li>
                                                    <ul className="px-1-0">
                                                        <li className="py-1">1. Application-Level Filtering: Host-based firewalls can examine network traffic at the application level, allowing or blocking specific applications or services based on predefined rules. This adds an additional layer of security by controlling the behavior of individual applications.</li>
                                                        <li className="py-1">2. Granular Control: Host-based firewalls offer granular control over network connections, allowing users to define rules for inbound and outbound traffic on a per-application or per-port basis. This flexibility enables users to customize security policies based on specific requirements.</li>
                                                        <li className="py-1">3. Intrusion Prevention: Host-based firewalls may include intrusion prevention features, such as detection and prevention of malicious activities, unauthorized access attempts, and known attack patterns. This helps protect the host system from various types of threats.</li>
                                                        <li className="py-1">4. Host Monitoring and Logging: Host-based firewalls can provide detailed logs and reports about network connections and attempted traffic. These logs are valuable for security analysis, monitoring, and forensic investigations.</li>
                                                    </ul>
                                                </ul>
                                            </p>
                                        </div>
                                        <p className="para__text">Firewalls, both network and host-based, play a crucial role in securing networks and systems by controlling and monitoring network traffic. They form a fundamental part of an organization's overall security infrastructure, complementing other security measures like antivirus software, intrusion detection systems, and secure coding practices. Regular updates, maintenance, and adherence to best practices are essential to ensure the effectiveness of firewall protection.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
            <div class="fixed-bottom right-100 p-3" style={{ zIndex: "6", left: "initial" }}>
                <a href="https://wa.me/919004392220?text=Hello How can I help you ?" target="_blank">
                    <img src={whatsappLogo} width="60" alt="aaaa" />
                </a>
            </div>
        </>
    )
};

export default FirewallAndSoftware;