import React from "react";
import { MiddleBanner } from "../Banner/Banner";
import MiddleBannerImg1 from '../../assets/img/banner/size-extra-large-wide/banner-home-1-img-1-extra-large-wide.jpg'
import Navbar from "../../Navbar";


import ServicesProduct1 from '../../assets/img/Services/Server & Desktop/product-gallery-large-1.jpg';
import ServicesProduct2 from '../../assets/img/Services/Server & Desktop/product-gallery-large-2.jpg';
import ServicesProduct3 from '../../assets/img/Services/Server & Desktop/product-gallery-large-3.jpg';
import ServicesProduct4 from '../../assets/img/Services/Server & Desktop/product-gallery-large-4.jpg';

import ProductDetails from "./ProductDetails";
import Footer from "../../Footer";
// import { Tab, TabList, Tabs } from "react-tabs";
import whatsappLogo from '../../assets/img/whatsapp.png';

const ServerAndDesktop = () => {
    return (
        <>

            <Navbar />
            <MiddleBanner MiddleBannerImg={MiddleBannerImg1} BannerHeading="Server & Desktop" />


            <div className="product-details mt-5">
                <div class="container">
                    <div class="row mt-5 pt-4">
                        <div class="col-md-5">
                            <div class="product-gallery-box m-b-60">
                                <div class="product-image--gallery overflow-hidden">
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct1} alt="" />
                                            {/* <img src="assets/img/product/gallery/gallery-large/product-gallery-large-2.jpg" alt="" /> */}
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct2} alt="" />
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct3} alt="" />
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct4} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-5">
                            <div class="product-details-box m-b-60 px-2">
                                <h5 className="h2 m-b-20">Server & Desktop</h5>

                                <div class="product__desc m-t-25 m-b-30">
                                    <p className='text-justify'>
                                        While server management focuses on centralized administration, security, and performance optimization, desktop management focuses on individual user environments, productivity, and user support. Effective
                                        management of both servers and desktops requires a combination of technical expertise, proactive monitoring, regular
                                        maintenance, and user support.
                                    </p>
                                </div>
                                <div class="product-var p-tb-30">
                                    <div class="product__stock m-b-20">
                                        <span class="product__stock--in"><i class="fas fa-check-circle"></i> IN STOCK</span>
                                    </div>
                                    {/* <div class="product-color product-var__item">
                                        <span class="product-var__text">Color</span>
                                        <div class="sidebar__color-filter ">
                                            <label class="product-color"><input name="large-product-color" type="radio" class="product-color-select" value="color-red" /><span></span></label>
                                            <label class="product-color" ><input name="large-product-color" type="radio" class="product-color-select" value="color-green" checked /><span></span></label>
                                            <label class="product-color" ><input name="large-product-color" type="radio" class="product-color-select" value="color-blue" /><span></span></label>
                                        </div>
                                    </div> */}

                                    {/* <div class="product-links">
                                        <a href="wishlist.html" class="link--gray link--icon-left shop__wishlist-icon m-b-5 m-r-20"><i class="ion-android-favorite-outline"></i>Add To Wishlist</a>
                                        <a href="compare.html" class="link--gray link--icon-left shop__wishlist-icon m-b-5"><i class="ion-ios-loop-strong"></i>Compare</a>
                                    </div> */}
                                    <div className='col-lg-12 col-md-12 d-none d-md-block d-lg-block'>

                                        <div class="nametag p-tb-10 ">
                                            <span className=''>Product:</span>
                                            <ul class="nametag__list d-flex justify-content-between">
                                                <li>Hardware,</li>
                                                <li>Electronics product,</li>
                                                <li>Installation,</li>
                                                <li>& Manage.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="nametag p-tb-10">
                                        <span>Share This Product :</span>
                                        <ul class="nametag__list d-flex justify-content-center">
                                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                            <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product-details-tab-area">
                <div class="container">
                    <div class="row pb-5">
                        <div class="col-12">
                            <ul class="tablist tablist--style-black tablist--style-title tablist--style-gap-70 nav">
                                <li><a class="nav-link active" data-bs-toggle="tab" href="#product-desc">Description</a></li>
                                {/* <li><a class="nav-link" data-bs-toggle="tab" href="#product-dis">Product Details</a></li> */}
                                {/* <li><a class="nav-link" data-bs-toggle="tab" href="#product-review">Reviews</a></li> */}
                            </ul>
                        </div>
                        <div class="col-md-12">
                            <div class="product-details-tab-box">
                                <div class="tab-content">
                                    {/* Start Tab - Product Description */}
                                    <div class="tab-pane show active" id="product-desc">
                                        <div class="para__content">
                                            <h6 class="para__title">Server:</h6>
                                            <p class="para__text">
                                                <ul class="para__list px-1">
                                                    <li className="py-1 px-0">Configuration and Deployment: Set up the server hardware, install the operating system, configure network settings, and install necessary server software.</li>
                                                    <li className="py-1 px-0">Security Management: Implement robust security measures, such as firewalls, intrusion detection systems, access controls, and encryption protocols. Regularly update and patch software to address vulnerabilities.</li>
                                                    <li className="py-1 px-0">User and Access Management: Create and manage user accounts with appropriate access privileges. Implement strong authentication mechanisms like passwords or multi-factor authentication to control access to the server.</li>
                                                    <li className="py-1 px-0">Storage and Backup Management: Configure storage devices and manage data storage, including implementing backup strategies and disaster recovery plans. Regularly back up critical data and test restore procedures.</li>
                                                    <li className="py-1 px-0">Performance Monitoring and Optimization: Monitor server performance, resource usage, and network traffic. Optimize server performance by identifying and addressing bottlenecks, optimizing configurations, and scaling resources as needed.</li>
                                                </ul>
                                            </p>
                                            <h6 class="para__title">Desktop:</h6>
                                            <p class="para__text">
                                                <ul class="para__list px-1">
                                                    <li className="py-1 px-0">Operating System Deployment: Deploy and configure desktop operating systems on individual machines or through imaging and deployment tools. Customize system settings and configurations.</li>
                                                    <li className="py-1 px-0">Application Deployment and Updates: Install and update necessary software applications on desktops, including productivity tools, security software, and line-of-business applications. Use centralized management tools for software distribution and patch management.</li>
                                                    <li className="py-1 px-0">User and Access Management: Create and manage user accounts on desktops with appropriate permissions and access levels. Implement password policies, user group management, and access controls.</li>
                                                    <li className="py-1 px-0">Security Management: Implement security measures such as antivirus software, firewalls, and encryption. Educate users about safe browsing habits, phishing awareness, and data protection practices.</li>
                                                    <li className="py-1 px-0">Asset and Inventory Management: Maintain an inventory of desktop hardware and software assets. Track warranties, licenses, and hardware configurations. Manage hardware upgrades and replacements.</li>
                                                </ul>
                                            </p>
                                        </div>
                                        <p className="para__text">While server management focuses on centralized administration, security, and performance optimization, desktop management focuses on individual user environments, productivity, and user support. Effective
                                            management of both servers and desktops requires a combination of technical expertise, proactive monitoring, regular
                                            maintenance, and user support.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
            <div class="fixed-bottom right-100 p-3" style={{ zIndex: "6", left: "initial" }}>
                <a href="https://wa.me/919004392220?text=Hello How can I help you ?" target="_blank">
                    <img src={whatsappLogo} width="60" alt="aaaa" />
                </a>
            </div>
        </>
    )
};

export default ServerAndDesktop;