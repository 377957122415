import React from "react";
import LatestProduct from "../homePage/LatestProductSection";
import '../../assets/css/vendor/jquery-ui.min.css'
import '../../assets/css/vendor/fontawesome.css'
import '../../assets/css/vendor/ionicons.min.css'
import '../../assets/css/vendor/bootstrap.min.css'
import '../../assets/css/main.css'
import 'bootstrap/dist/css/bootstrap.min.css';


import productImg1 from '../../assets/img/pageImage/mobile-component.jpg'
import productImg2 from '../../assets/img/pageImage/computer-hardware-1.jpg'
import productImg3 from '../../assets/img/pageImage/cameras-component.jpg'
import productImg4 from '../../assets/img/pageImage/telicom.jpg'
import productImg5 from '../../assets/img/pageImage/hardware-solution-2.jpg'
import productImg6 from '../../assets/img/pageImage/cctv-camera-9.jpg'
import productImg7 from '../../assets/img/pageImage/cameras.jpg'
import productImg8 from '../../assets/img/pageImage/cctv-wires.jpg'
import productImg9 from '../../assets/img/pageImage/fire-alarm-system.jpg'
import productImg10 from '../../assets/img/pageImage/hardware-1.jpg'
import productImg11 from '../../assets/img/pageImage/network-solution.jpg'
import productImg12 from '../../assets/img/pageImage/telicom.jpg'


import ServicesProduct1 from '../../assets/img/product/gallery/gallery-large/product-gallery-large-1.jpg';
import ServicesProduct2 from '../../assets/img/product/gallery/gallery-large/product-gallery-large-2.jpg';
import ServicesProduct3 from '../../assets/img/product/gallery/gallery-large/product-gallery-large-3.jpg';
import ServicesProduct4 from '../../assets/img/product/gallery/gallery-large/product-gallery-large-4.jpg';

import { CardGroup, Carousel } from "react-bootstrap";


const ServiceCard = (prop) => {

    return (
        <>
            <div class="cms cms--1 m-t-50">
                <div class="cms__content">
                    <div class="cms__icon">
                        <img src={prop.cardImg} alt="" className="cms__icon-img" style={{ height: "90px", width: "auto" }} />
                    </div>
                    <div class="cms__text">
                        <h6 class="cms__title white-orange">{prop.cardTittle}</h6>
                        <span class="cms__des">{prop.carddes}</span>
                    </div>
                </div>
            </div>
        </>

    );
}

const ServiceSliderCard = (prop) => {

    return (
        <>
            <div className="">
                <div className="card bg-transparent mb-4 pb-2 mx-auto glossy-flash-animate">
                    {/* <img src="..." class="card-img-top" alt="..."/> */}
                    <img className="card-img-top mx-auto d-block product__img img-fluid" src={prop.cardImg} alt="" style={{ backgroundSize: "cover", height: "300px", width: "400px" }} />
                    <div class="card-body">
                        <h5 class="card-title">{prop.cardHeading}</h5>
                        <h5 class="card-title">{prop.cardDate}</h5>
                        <p class="card-text">{prop.cardContent}</p>
                        <a href="#" className="text-black text-decoration-none float-right">{prop.cardLink}<hr className="m-0 p-0"></hr></a>
                    </div>
                </div>
            </div>
        </>

    );

}


const ServiceSlider = () => {
    return (
        <>
            <div class="blog p-tb-50">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            {/* Start Section Title */}
                            <div class="section-content m-b-35 text-center">
                                <h5 class="section-content__title">Some product</h5>
                                <a href="blog-list-sidebar-left.html" class="section-content__desc py-2">View All Posts</a>
                            </div>
                            {/* End Section Title */}
                        </div>
                    </div>


                    <div className="row mx-auto">
                        <Carousel>
                            <Carousel.Item >
                                <CardGroup className="mx-auto">
                                    <div className="col-md-4 col-lg-4 col-12">
                                        <ServiceSliderCard cardImg={productImg1} cardHeading="Mobile Hardware" cardLink="Read More" />
                                    </div>
                                    <div className="col-md-4 col-lg-4 col-12">
                                        <ServiceSliderCard cardImg={productImg2} cardHeading="System component" cardLink="Read More" />
                                    </div>
                                    <div className="col-md-4 col-lg-4 col-12">
                                        <ServiceSliderCard cardImg={productImg3} cardHeading="Camera Hardware" cardLink="Read More" />
                                    </div>
                                </CardGroup>
                                <CardGroup className="mx-auto mt-4">
                                    <div className="col-md-4 col-lg-4 col-12">
                                        <ServiceSliderCard cardImg={productImg4} cardHeading="TeliCommunication" cardLink="Read More" />
                                    </div>
                                    <div className="col-md-4 col-lg-4 col-12">
                                        <ServiceSliderCard cardImg={productImg5} cardHeading="Mobile Network Booster" cardLink="Read More" />
                                    </div>
                                    <div className="col-md-4 col-lg-4 col-12">
                                        <ServiceSliderCard cardImg={productImg6} cardHeading="Street Wall Camera" cardLink="Read More" />
                                    </div>
                                </CardGroup>
                            </Carousel.Item>
                            <Carousel.Item >
                                <CardGroup className="mx-auto">
                                    <div className="col-md-4 img-fluid col-12">
                                        <ServiceSliderCard cardImg={productImg7} cardHeading="Wall Camera" cardLink="Read More" />
                                    </div>
                                    <div className="col-md-4 img-fluid col-12">
                                        <ServiceSliderCard cardImg={productImg8} cardHeading="cctv connection Wires" cardLink="Read More" />
                                    </div>
                                    <div className="col-md-4 img-fluid col-12">
                                        <ServiceSliderCard cardImg={productImg9} cardHeading="Fire Alarm System" cardLink="Read More" />
                                    </div>
                                </CardGroup>
                                <CardGroup className="mx-auto mt-4">
                                    <div className="col-md-4 img-fluid col-12">
                                        <ServiceSliderCard cardImg={productImg10} cardHeading="mobile network-system" cardLink="Read More" />
                                    </div>
                                    <div className="col-md-4 img-fluid col-12">
                                        <ServiceSliderCard cardImg={productImg11} cardHeading="network solution" cardLink="Read More" />
                                    </div>
                                    <div className="col-md-4 img-fluid col-12">
                                        <ServiceSliderCard cardImg={productImg12} cardHeading="TeliCommunication" cardLink="Read More" />
                                    </div>
                                </CardGroup>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </div>

            {/* ::::::  End  Blog News   ::::::  */}
        </>
    )
};

const productDetails = (prop) => {

    return (
        <>
            <div className="product-details mt-5">
                <div class="container">
                    <div class="row mt-5 pt-4">
                        <div class="col-md-5">
                            <div class="product-gallery-box m-b-60">
                                <div class="product-image--gallery">
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct1} alt="" />
                                            <img src={prop.ServicesProduct1} alt="" />
                                            {/* <img src="assets/img/product/gallery/gallery-large/product-gallery-large-2.jpg" alt="" /> */}
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct2} alt="" />
                                            <img src={prop.ServicesProduct2} alt="" />
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct3} alt="" />
                                            <img src={prop.ServicesProduct3} alt="" />
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct4} alt="" />
                                            <img src={prop.ServicesProduct4} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 ">
                            <div class="product-details-box m-b-60 px-2">
                                <h5 className="h2 m-b-20">Infotech Solution{prop.serviceProductHeader}</h5>

                                <div class="product__desc m-t-25 m-b-30">
                                    <p className='text-justify'>Has the product been a good partner in doing business, Availability, Intrusion Prevention,
                                        and Continuous Analysis are the top four factors that positively impact user satisfaction for Firewall Software products.
                                    </p>
                                </div>
                                <div class="product-var p-tb-30">
                                    <div class="product__stock m-b-20">
                                        <span class="product__stock--in"><i class="fas fa-check-circle"></i> IN STOCK</span>
                                    </div>
                                    {/* <div class="product-color product-var__item">
                                        <span class="product-var__text">Color</span>
                                        <div class="sidebar__color-filter ">
                                            <label class="product-color"><input name="large-product-color" type="radio" class="product-color-select" value="color-red" /><span></span></label>
                                            <label class="product-color" ><input name="large-product-color" type="radio" class="product-color-select" value="color-green" checked /><span></span></label>
                                            <label class="product-color" ><input name="large-product-color" type="radio" class="product-color-select" value="color-blue" /><span></span></label>
                                        </div>
                                    </div> */}

                                    {/* <div class="product-links">
                                        <a href="wishlist.html" class="link--gray link--icon-left shop__wishlist-icon m-b-5 m-r-20"><i class="ion-android-favorite-outline"></i>Add To Wishlist</a>
                                        <a href="compare.html" class="link--gray link--icon-left shop__wishlist-icon m-b-5"><i class="ion-ios-loop-strong"></i>Compare</a>
                                    </div> */}
                                    <div class="nametag p-tb-10 d-none d-lg-block d-md-block">
                                        <span>Product:</span>
                                        <ul class="nametag__list d-flex justify-content-between">
                                            <li>Hardware,</li>
                                            <li>Electronics,</li>
                                            <li> Installation,</li>
                                            <li>Server & Dekstop,</li>
                                            <li>Solution.</li>
                                        </ul>
                                    </div>
                                    <div class="nametag p-tb-10">
                                        <span>Share This Product</span>
                                        <ul class="nametag__list d-flex">
                                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                            <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

const ServiceContent = (prop) => {

    return (
        <>

            <div className="container m-tb-50 ">

                <div className=" my-4">
                    <div className="row d-flex align-items-center justify-content-between d-sm-column">
                        <div className="col-md-6">
                            <img src={prop.AboutImgSec1} alt="" className="img-fluid border border-dark" style={{ width: "500px" }} />
                        </div>
                        <div className="col-md-6">
                            <h1 className="font--semi-bold">{prop.AboutImgHeaderSec1}</h1>
                            <p className="para__text">{prop.AboutImgInfoSec1}</p>
                        </div>
                    </div>
                </div>

                <div className="container my-4">
                    <div className="row d-flex align-items-center justify-content-between d-sm-column">
                        <div className="col-md-6">
                            <h1 className="font--semi-bold">{prop.AboutImgHeaderSec2}</h1>
                            <p className="para__text">{prop.AboutImgInfoSec2}</p>
                        </div>
                        <div className="col-md-6">
                            <img src={prop.AboutImgSec2} alt="" className="img-fluid my-4 border border-dark" style={{ width: "500px", }} />
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default ServiceCard;
export { ServiceSlider };
export { ServiceSliderCard };
export { productDetails };
export { ServiceContent };

