import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../src/assets/css/vendor/jquery-ui.min.css'
import '../src/assets/css/vendor/fontawesome.css'
import '../src/assets/css/vendor/ionicons.min.css'
import '../src/assets/css/vendor/bootstrap.min.css'
import '../src/assets/css/main.css'
import Logo1 from './assets/img/logo/logo-color-red.png';
import Logo from './assets/img/logo/logo.png';
import Logo2 from './assets/img/logo/logo-color-black.png';
// import { useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";

// import { CCloseButton, COffcanvas, COffcanvasBody, COffcanvasHeader, COffcanvasTitle } from '@coreui/react'
// import { Offcanvas } from 'bootstrap'

const Navbar = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>


            {/* <h1>This is Navbar</h1> */}
            <header className="fixed-top nav-header" style={{ backgroundColor: "#fff" }}>
                {/* {/*  Start Large Header Section   */}
                <div class="header header--1 d-none d-md-block d-lg-block">

                    {/* {/* Start Header Middle area */}
                    <div class="header__middle sticky-header header__color--silver p-tb-10 d-flex justify-content-between">
                        <div class="container " >
                            <div className="row justify-content-between d-flex  d-md-12" >
                                <div class="col-xl-4 col-md-3 col-2 d-flex justify-content-between">
                                    {/* {/* Start Logo */}
                                    <div class="header__logo header__logo--1">
                                        <NavLink className="navbar-brand" to="/">
                                            <img src={Logo} style={{ width: "160px" }} alt="" />
                                        </NavLink>
                                    </div>
                                    {/* {/* End Logo */}

                                    {/* Start Search */}
                                    {/* <form class="header__search-form" action="#">
                                            <div class="header__search-input">
                                                <button type="submit"><i class="ion-ios-search-strong"></i></button>
                                                <input type="search" placeholder="Search Product..." required />
                                            </div>
                                        </form> */}
                                    {/* {/* End Search */}
                                </div>

                                <div class="col-xl-8 col-md-8 col-10" >
                                    {/* {/* Start Header Menu */}
                                    <div className="header-menu d-flex justify-content-between">
                                        <nav>
                                            <ul class="header__nav d-flex justify-content-between">

                                                <li class="header__nav-item pos-relative">
                                                    <NavLink to="/" className="header__nav-link header__nav-link--black header__nav-link--white-hover-white">Home</NavLink>
                                                </li>

                                                <li class="header__nav-item pos-relative">
                                                    <NavLink to="/about" className="header__nav-link header__nav-link--black header__nav-link--white-hover-white">About Us</NavLink>
                                                </li>

                                                <li class="header__nav-item pos-relative">
                                                    <NavLink to="/services" className="header__nav-link header__nav-link--black header__nav-link--white-hover-white">Services<i className="ion-ios-arrow-down"></i></NavLink>
                                                    <ul class="dropdown__menu pos-absolute">
                                                        <li class="dropdown__list">
                                                            {/* <a href="#" class="dropdown__link">Server & Desktop</a> */}
                                                            <NavLink to='/server-desktop' className="dropdown__link dropdown__submenu-link" >Server & Desktop</NavLink>
                                                        </li>

                                                        <li class="dropdown__list">
                                                            <NavLink to='/firewall-Software' className="dropdown__link dropdown__submenu-link" >Firewall & Software</NavLink>
                                                        </li>

                                                        <li class="dropdown__list">
                                                            <NavLink to='/network-Solutions' className="dropdown__link dropdown__submenu-link" >Network Solutions</NavLink>
                                                        </li>
                                                        <li class="dropdown__list">
                                                            <NavLink to='/mobile-network-booster' className="dropdown__link dropdown__submenu-link" >Mobile Network Booster</NavLink>
                                                        </li>
                                                        <li class="dropdown__list">
                                                            <NavLink to='/teliCommunication' className="dropdown__link dropdown__submenu-link" >TeliCommunication & PBX</NavLink>
                                                        </li>
                                                        <li class="dropdown__list">
                                                            <NavLink to='/video-conferencing' className="dropdown__link dropdown__submenu-link" >Video Conferencing System</NavLink>
                                                        </li>
                                                        <li class="dropdown__list">
                                                            <NavLink to='/cctv-security' className="dropdown__link dropdown__submenu-link" >CCTV & Security</NavLink>
                                                        </li>
                                                        <li class="dropdown__list">
                                                            <NavLink to='/firealarm-System' className="dropdown__link dropdown__submenu-link" >Fire Alarm System</NavLink>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li class="header__nav-item pos-relative">
                                                    <NavLink to="/contact" className="header__nav-link header__nav-link--black header__nav-link--white-hover-white">Contact Us</NavLink>
                                                </li>
                                            </ul>

                                        </nav>
                                    </div>

                                    <ul class="header__user-action-icon">

                                        {/* Start Header Wishlist Box */}
                                        {/* <li class="header__wishlist pos-relative">
                                            <a href="wishlist.html" class="header__wishlist-link">
                                                <i class="ion-android-favorite-outline"></i>
                                                <span class="wishlist-item-count wishlist-item-count--red pos-absolute">3</span>
                                            </a>
                                        </li>

                                        <li class="header-add-cart pos-relative">
                                            <a href="#offcanvas-add-cart__box" class="offcanvas-toggle">
                                                <i class="ion-bag"></i>
                                                <span class="wishlist-item-count wishlist-item-count--red pos-absolute">3</span>
                                            </a>
                                        </li> */}

                                        <li class="header-user-sidebar pos-relative">
                                            {/* <a href="#offcanvas-user" class="offcanvas-toggle">
                                                <i class="ion-navicon-round" variant="primary" onClick={handleShow}></i>
                                            </a> */}

                                            <Offcanvas placement='end' show={show} onHide={handleClose}>
                                                <Offcanvas.Header closeButton>
                                                    <Offcanvas.Title><img src={Logo2} style={{ width: "150px" }} alt="" className='' /></Offcanvas.Title>
                                                </Offcanvas.Header>
                                                <Offcanvas.Body >
                                                    <div class="col-lg-2 col-sm-6 col-12">
                                                        <div class="footer__menu ">
                                                            <h4 class="footer__nav-title text-dark">quick link</h4>
                                                            <ul class="footer__nav toggle-Nav">
                                                                <li class="footer__list"><NavLink to="/" className="footer__link text-dark active ">HOME</NavLink></li>
                                                                <li class="footer__list"><NavLink to="/about" className="footer__link text-dark">ABOUT</NavLink></li>
                                                                <li class="footer__list header__nav-item pos-relative"><NavLink className="footer__link text-dark">SERVICES <i className="ion-ios-arrow-down" style={{ padding: "10px" }}>
                                                                    <ul className="dropdown__menu toggle-sub-nav pos-absolute toggle-Nav">
                                                                        <li className="dropdown__list py-2"><NavLink to='/server-desktop' className="dropdown__link dropdown__submenu-link" >Server & Desktop</NavLink></li>
                                                                        <li className="dropdown__list py-2"><NavLink to='/firewall-Software' className="dropdown__link dropdown__submenu-link" >Firewall & Software</NavLink></li>
                                                                        <li className="dropdown__list py-2"><NavLink to='/network-Solutions' className="dropdown__link dropdown__submenu-link" >Network Solutions</NavLink></li>
                                                                        <li className="dropdown__list py-2"><NavLink to='/mobile-network-booster' className="dropdown__link dropdown__submenu-link" >Mobile Network Booster</NavLink></li>
                                                                        <li className="dropdown__list py-2"><NavLink to='/teliCommunication' className="dropdown__link dropdown__submenu-link" >TeliCommunication & PBX</NavLink></li>
                                                                        <li className="dropdown__list py-2"><NavLink to='/video-conferencing' className="dropdown__link dropdown__submenu-link" >Video Conferencing System</NavLink></li>
                                                                        <li className="dropdown__list py-2"><NavLink to='/cctv-security' className="dropdown__link dropdown__submenu-link" >CCTV & Security</NavLink></li>
                                                                        <li className="dropdown__list py-2"><NavLink to='/firealarm-System' className="dropdown__link dropdown__submenu-link" >Fire Alarm System</NavLink></li>
                                                                    </ul>
                                                                </i></NavLink></li>

                                                                <li class="footer__list"><NavLink to="/contact" className="footer__link text-dark">CONTACT US</NavLink></li>
                                                            </ul>

                                                        </div>
                                                    </div>

                                                    <div class="footer__about">
                                                        <ul className="footer__address p-0 text-dark text-center">
                                                            <h5 class="offcanvas-title align-left">Address</h5>
                                                            <li className="footer__address-item text-dark py-2"> A7,Virat Balaji darshan Patharli Road Gogras Wadi Dombivali East.</li>
                                                            <li class="footer__address-item text-justify text-dark py-2">Call Us: (+91) 9004392220</li>
                                                            <li class="footer__address-item text-justify text-dark py-2">Email: Infotec@example.com</li>
                                                        </ul>
                                                        <ul className="footer__social-nav py-3 px-0 d-flex justify-content-center text-black">
                                                            <li class="footer__social-list px-2 "><a href="#"
                                                                class="footer__social-link"><i
                                                                    class="fab fa-facebook-f"></i></a></li>
                                                            <li class="footer__social-list px-2 "><a href="#"
                                                                class="footer__social-link"><i
                                                                    class="fab fa-twitter"></i></a></li>
                                                            <li class="footer__social-list px-2 "><a href="#"
                                                                class="footer__social-link"><i
                                                                    class="fab fa-youtube text-danger"></i></a></li>
                                                            <li class="footer__social-list px-2 "><a href="#"
                                                                class="footer__social-link"><i
                                                                    class="fab fa-google-plus-g text-dark"></i></a></li>
                                                            <li class="footer__social-list px-2 "><a href="#"
                                                                class="footer__social-link"><i
                                                                    class="fab fa-instagram text-dark"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </Offcanvas.Body>
                                            </Offcanvas >

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* --------- toggle nav ------------ */}


                <div class="offcanvas-overlay ">
                    <div className='justify-content-between'>
                        <a href="#offcanvas-user" class="offcanvas-toggle d-md-none d-lg-none ">
                            <i className="ion-navicon-round text-light" variant="primary" onClick={handleShow} style={{ fontSize: "42px" }} ></i>
                        </a>
                        <img src={Logo} style={{ width: "144px" }} alt="" className='d-lg-none d-md-none d-sm-block float-end' />
                    </div>
                </div>

            </header>
        </>
    )
}


export default Navbar;