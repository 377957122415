

import React from "react";
import { MiddleBanner } from "../Banner/Banner";
import MiddleBannerImg1 from '../../assets/img/banner/size-extra-large-wide/banner-home-1-img-1-extra-large-wide.jpg'
import Navbar from "../../Navbar";
import ProductDetails from "./ProductDetails";
import Footer from "../../Footer";


import ServicesProduct1 from '../../assets/img/Services/FireAlarmSystem/product-gallery-large-1.jpg';
import ServicesProduct2 from '../../assets/img/Services/FireAlarmSystem/product-gallery-large-2.jpg';
import ServicesProduct3 from '../../assets/img/Services/FireAlarmSystem/product-gallery-large-3.jpg';
import ServicesProduct4 from '../../assets/img/Services/FireAlarmSystem/product-gallery-large-4.jpg';
import whatsappLogo from '../../assets/img/whatsapp.png';

const FireAlarmSystem = () => {
    return (
        <>
            <Navbar />
            <MiddleBanner MiddleBannerImg={MiddleBannerImg1} BannerHeading="Fire Alarm System" />
            <div className="product-details mt-5">
                <div class="container">
                    <div class="row mt-5 pt-4">
                        <div class="col-md-5">
                            <div class="product-gallery-box m-b-60">
                                <div class="product-image--gallery overflow-hidden">
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct1} alt="" />
                                            {/* <img src="assets/img/product/gallery/gallery-large/product-gallery-large-2.jpg" alt="" /> */}
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct2} alt="" />
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct3} alt="" />
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct4} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-5">
                            <div class="product-details-box m-b-60 px-2">
                                <h5 className="h2 m-b-20">Fire Alarm System</h5>

                                <div class="product__desc m-t-25 m-b-30">
                                    <p className='text-justify'>A fire alarm system is a critical component of fire safety measures designed to detect and alert occupants of a building or area in the event of a fire. It comprises a network of interconnected devices, sensors, and control panels that work together to detect smoke, heat, or flames and provide early warning of a potential fire emergency. The primary goal of a fire alarm system is to ensure swift evacuation, minimize property damage, and save lives.
                                    </p>
                                </div>
                                <div class="product-var p-tb-30">
                                    <div class="product__stock m-b-20">
                                        <span class="product__stock--in"><i class="fas fa-check-circle"></i> IN STOCK</span>
                                    </div>
                                    {/* <div class="product-color product-var__item">
                                        <span class="product-var__text">Color</span>
                                        <div class="sidebar__color-filter ">
                                            <label class="product-color"><input name="large-product-color" type="radio" class="product-color-select" value="color-red" /><span></span></label>
                                            <label class="product-color" ><input name="large-product-color" type="radio" class="product-color-select" value="color-green" checked /><span></span></label>
                                            <label class="product-color" ><input name="large-product-color" type="radio" class="product-color-select" value="color-blue" /><span></span></label>
                                        </div>
                                    </div> */}

                                    {/* <div class="product-links">
                                        <a href="wishlist.html" class="link--gray link--icon-left shop__wishlist-icon m-b-5 m-r-20"><i class="ion-android-favorite-outline"></i>Add To Wishlist</a>
                                        <a href="compare.html" class="link--gray link--icon-left shop__wishlist-icon m-b-5"><i class="ion-ios-loop-strong"></i>Compare</a>
                                    </div> */}
                                    <div className='col-lg-12 col-md-12 d-none d-md-block d-lg-block'>

                                        <div class="nametag p-tb-10 ">
                                            <span className=''>Product:</span>
                                            <ul class="nametag__list d-flex justify-content-between">
                                                <li>Wireless,</li>
                                                <li>Conventional, and</li>
                                                <li>Analogue-Addressable</li>
                                                <li>Fire Alarm.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="nametag p-tb-10">
                                        <span>Share This Product :</span>
                                        <ul class="nametag__list d-flex justify-content-center">
                                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                            <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product-details-tab-area">
                <div class="container">
                    <div class="row pb-5">
                        <div class="col-12">
                            <ul class="tablist tablist--style-black tablist--style-title tablist--style-gap-70 nav">
                                <li><a class="nav-link active" data-bs-toggle="tab" href="#product-desc">Description</a></li>
                            </ul>
                        </div>
                        <div class="col-md-12">
                            <div class="product-details-tab-box">
                                <div class="tab-content">
                                    {/* Start Tab - Product Description */}
                                    <div class="tab-pane show active" id="product-desc">
                                        <div class="para__content">
                                            <h6 class="para__title">Fire Alarm System :</h6>
                                            <p class="para__text">
                                                <p className="para__text">A fire alarm system is a critical component of fire safety measures designed to detect and alert occupants of a building or area in the event of a fire. It comprises a network of interconnected devices, sensors, and control panels that work together to detect smoke, heat, or flames and provide early warning of a potential fire emergency. The primary goal of a fire alarm system is to ensure swift evacuation, minimize property damage, and save lives.
                                                </p>
                                                <h6 class="para__title">Key components and features of a fire alarm system include:</h6>
                                                <ul class="para__list px-1">
                                                    <li className="py-1">Fire Detectors: Fire alarm systems include various types of detectors, such as smoke detectors, heat detectors, flame detectors, and carbon monoxide detectors. These detectors continuously monitor the environment and identify signs of fire or hazardous conditions.</li>
                                                    <ul className="py-1">
                                                        <li>1. Smoke Detectors: These devices sense the presence of smoke particles and trigger an alarm when smoke is detected.</li>
                                                        <li>2. Heat Detectors: Heat detectors respond to a rapid increase in temperature or when a predetermined temperature threshold is reached.</li>
                                                        <li>3. Flame Detectors: Flame detectors are designed to detect the presence of open flames, including those that are not visible to the naked eye.</li>
                                                    </ul>
                                                    <li className="py-1">Control Panels: The control panel is the central hub of the fire alarm system. It receives signals from the detectors, processes the information, and activates audible and visual alarms when a fire is detected. The control panel also provides status information, control functions, and may be connected to a central monitoring station or fire department.</li>
                                                    <li className="py-1">Notification Devices: Fire alarm systems include devices that alert occupants of a fire emergency. These devices can include sirens, horns, strobe lights, or voice evacuation systems. They are strategically placed throughout the building to ensure that alarms are clearly audible and visible in all areas.</li>
                                                    <li className="py-1">Manual Call Points: Manual call points, also known as fire alarm pull stations, are manually activated devices that allow individuals to initiate a fire alarm by breaking a glass or pressing a button in case of an emergency. They provide a quick and visible means for occupants to trigger an alarm when they spot a fire.</li>
                                                    <li className="py-1">Backup Power: Fire alarm systems are often equipped with backup power supplies, typically in the form of batteries or generators. This ensures the system's continued operation during power outages, which is crucial for uninterrupted fire detection and notification.</li>
                                                    <li className="py-1">Testing and Maintenance: Regular testing and maintenance are essential to ensure the reliable operation of a fire alarm system. This includes inspecting detectors, testing alarm signals, verifying battery life, and conducting system-wide tests to identify any faults or malfunctions.</li>
                                                </ul>
                                            </p>

                                        </div>
                                        <p className="para__text">Fire alarm systems are designed to provide early detection of fires, enabling occupants to evacuate quickly and safely. They play a vital role in protecting lives, safeguarding property, and enabling a timely response from emergency services. Compliance with local fire codes, standards, and regulations is necessary to ensure the proper installation, operation, and maintenance of a fire alarm system.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
            <div class="fixed-bottom right-100 p-3" style={{ zIndex: "6", left: "initial" }}>
                <a href="https://wa.me/919004392220?text=Hello How can I help you ?" target="_blank">
                    <img src={whatsappLogo} width="60" alt="aaaa" />
                </a>
            </div>
        </>
    )
};

export default FireAlarmSystem;