import React from "react";
import { CompanyLogos } from "../about/AboutSection";

import CompanyLogo1 from '../../assets/img/company-logo/company-logo-1.png';
import CompanyLogo2 from '../../assets/img/company-logo/company-logo-2.png';
import CompanyLogo3 from '../../assets/img/company-logo/company-logo-3.png';
import CompanyLogo4 from '../../assets/img/company-logo/company-logo-4.png';
import CompanyLogo5 from '../../assets/img/company-logo/company-logo-5.png';
import CompanyLogo6 from '../../assets/img/company-logo/company-logo-6.png';
import CompanyLogo7 from '../../assets/img/company-logo/company-logo-7.png';
import CompanyLogo8 from '../../assets/img/company-logo/company-logo-8.png';


const CompanyLogo = () => {
    return (
        <>


            {/* -------- company logo ---------- */}

            <div class="company-logo py-4 mt-2 d-none d-md-block">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div className="company-logo__area ">
                                <div class="company-logo__item">
                                    <a href="#" className="d-flex company-logo__link d-flex justify-content-between">
                                        <CompanyLogos CompanyLogo={CompanyLogo1} />
                                        <CompanyLogos CompanyLogo={CompanyLogo2} />
                                        <CompanyLogos CompanyLogo={CompanyLogo3} />
                                        <CompanyLogos CompanyLogo={CompanyLogo4} />
                                        <CompanyLogos CompanyLogo={CompanyLogo5} />
                                        
                                        {/* <CompanyLogos CompanyLogo={CompanyLogo6} />
                                        <CompanyLogos CompanyLogo={CompanyLogo7} />
                                        <CompanyLogos CompanyLogo={CompanyLogo8} /> */}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default CompanyLogo;