import React from "react";
import '../../assets/css/main.css'


const ServiceCard = (prop) => {

    return (
        <>
            <div class="cms cms--1 m-t-100">
                <div class="cms__content">
                    <div class="cms__icon">
                        <img src={prop.cardImg} alt="" class="cms__icon-img" />
                    </div>
                    <div className="cms__text">
                        <h6 className="cms__title uppercase white-orange">{prop.cardTittle}</h6>
                        <span classNameName="cms__des text-justify">{prop.cardDescription}</span>
                    </div>
                </div>
            </div>
        </>
    );
}

const TeamLeader = (prop) => {

    return (
        <>
            <div classNameName="col-sm-3 team-single">
                <div classNameName='team-single-body shadow py-5'>
                    <div className=" team-img">
                        {/* <img src={leader1} alt="" classNameName='img-fluid' /> */}
                        <img src={prop.LeaderImg} alt="" classNameName='img-fluid' />
                    </div>
                    <div className="team-content pos-relative">
                        <h6 className="team-name font--bold m-t-25">{prop.LeaderName}</h6>
                        <span className="team-title">{prop.LeaderTitle}</span>
                        <ul className="team-social pos-absolute">
                            <li><a href="#"><i className="ion-social-facebook"></i></a></li>
                            <li><a href="#"><i className="ion-social-twitter"></i></a></li>
                            <li><a href="#"><i className="ion-social-instagram"></i></a></li>
                            <li><a href="#"><i className="ion-social-linkedin"></i></a></li>
                            <li><a href="#"><i className="ion-social-rss"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

const CompanyLogos = (prop) => {

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div classNameName="company-logo__area d-flex justify-content-between">
                            <div classNameName="company-logo__item">
                                <a href="#" className="company-logo__link d-flex">
                                    <img src={prop.CompanyLogo} alt="" classNameName='company-logo__img img-fluid' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const AboutContent = (prop) => {

    return (
        <>
            <div className="container mt-5 pt-4">
                <div className="row d-flex align-items-center justify-content-between d-sm-column ">
                    <div className="col-md-12 col-lg-5">
                        <div className="">
                            <img src={prop.AboutImg1} alt="" className="img-fluid shadow-lg" style={{ border: "1px solid #000" }} />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6 px-3 ">
                        <div className="about-content">
                            <h1 className="font--semi-bold py-4 text-justify"><span className="white-orange">{prop.AboutImgHeader1}</span>{prop.AboutImgHeader2}</h1>
                            <h1 className="font--semi-bold py-4 text-justify">{prop.AboutImgHeader3}</h1>
                            <h5 className="font--semi-bold text-justify">{prop.AboutImgContent1}</h5>
                            <p className="para__text">{prop.AboutImgInfo1}</p>
                            <p className="para__text">{prop.AboutImgInfo2}</p>
                            <p className="para__text">{prop.AboutImgInfo3}</p>
                            <p className="para__text">{prop.AboutImgInfo4}</p>
                            <p className="para__text">{prop.AboutImgInfo5}</p>
                            <p className="para__text">{prop.AboutImgInfo6}</p>
                            <p className="para__text">{prop.AboutImgInfo7}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container my-4">
                <div className="row d-flex align-items-center justify-content-between d-sm-column">

                    <div className="col-md-6">
                        <div className="about-content">
                            <h1 className="font--semi-bold">{prop.AboutImgHeaderSec2}</h1>
                            <h5 className="font--semi-bold">{prop.AboutImgContentSec2}</h5>
                            <p className="para__text">{prop.AboutImgInfoSec2}</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="about-img ">
                            <div className="img-responsive py-2">
                                <img src={prop.AboutImgSec2} alt="" className="img-fluid" style={{ width: "500px" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}



export default ServiceCard;
export { TeamLeader };
export { CompanyLogos };
export { AboutContent };
