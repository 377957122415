
// // Vendor CSS Files
// import '../assets/css/vendor/jquery-ui.min.css'
// import '../assets/css/vendor/fontawesome.css'
// import '../assets/css/vendor/ionicons.min.css'
// import '../assets/css/vendor/bootstrap.min.css'

// // Plugin CSS Files
// import '../assets/css/plugin/slick.css'
// import '../assets/css/plugin/material-scrolltop.css'
// import '../assets/css/plugin/price_range_style.css'
// import '../assets/css/plugin/in-number.css'
// import '../assets/css/plugin/venobox.min.css'
// import '../assets/css/plugin/jquery.lineProgressbar.css'

// // Vendor JS Files
// import '../assets/js/vendor/jquery-3.6.0.min.js'
// import '../assets/js/vendor/modernizr-3.7.1.min.js'
// import '../assets/js/vendor/jquery-ui.min.js'
// import '../assets/js/vendor/bootstrap.bundle.min.js'

// // Plugins JS Files
// import '../assets/js/plugin/slick.min.js'
// import '../assets/js/plugin/jquery.countdown.min.js'
// import '../assets/js/plugin/material-scrolltop.js'
// import '../assets/js/plugin/price_range_script.js'
// import '../assets/js/plugin/in-number.js'
// import '../assets/js/plugin/jquery.elevateZoom-3.0.8.min.js'
// import '../assets/js/plugin/venobox.min.js'
// import '../assets/js/plugin/jquery.waypoints.js'
// import 'assets/js/plugin/jquery.lineProgressbar.js'

// // main JS
// import '../assets/js/main.js'
// // main css
// import '../assets/css/main.css'



import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../src/Pages/homePage/Home'
import About from './Pages/about/About';
import NoPage from './Pages/404Page/NoPage';
import Footer from './Footer';
import Cms from './Pages/cms section/Cms';
import Banner from './Pages/Banner/Banner';
import ContactUs from './Pages/contact/ContactUs';
import Services from './Pages/services/Services';
import Navbar from './Navbar';
import CompanyLogo from './Pages/company/CompanyLogo';
import ServerAndDesktop from './Pages/services/ServerAndDesktop';
import FireAlarmSystem from './Pages/services/FireAlarmSystem';
import MobileNetworkBooster from './Pages/services/MobileNetworkBooster';
import VideoConferencingSystem from './Pages/services/VideoConferencingSystem';
import ServicesSection from './Pages/services/ServicesSection';
import CCTVandSecurity from './Pages/services/CCTVandSecurity';
import TeliCommunicationAndPBX from './Pages/services/TeliCommunicationAndPBX';
import FirewallAndSoftware from './Pages/services/FirewallAndSoftware';
import NetworkAndSolutions from './Pages/services/NetworkAndSolution';
import ScrollToTop from './ScrollTop';



function App() {
  return (
    <>


      <BrowserRouter>
        <ScrollToTop />
        <Routes>

          <Route path='/' element={<Home />} />
          <Route path='/nav' element={<Navbar />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path='/cms' element={<Cms />} />
          <Route path='/banner' element={<Banner />} />
          <Route path='/companyLogo' element={< CompanyLogo />} />
          <Route path='/service-section' element={< ServicesSection />} />
          <Route path='/server-desktop' element={< ServerAndDesktop />} />
          <Route path='/cctv-security' element={< CCTVandSecurity />} />
          <Route path='/firealarm-System' element={<FireAlarmSystem />} />
          <Route path='/firewall-Software' element={<FirewallAndSoftware />} />
          <Route path='/mobile-network-booster' element={< MobileNetworkBooster />} />
          <Route path='/network-Solutions' element={< NetworkAndSolutions />} />
          <Route path='/teliCommunication' element={< TeliCommunicationAndPBX />} />
          <Route path='/video-conferencing' element={< VideoConferencingSystem />} />


          <Route path='/*' element={<NoPage />} />
          <Route path='/footer' element={<Footer />} />
        </Routes>
      </BrowserRouter>

      {/* <ScriptTag isHydrating={true} type="text/javascript" src="../src/assets/js/main.js" /> */}
    </>
  );
}

export default App;
