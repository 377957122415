

import React from "react";
import { MiddleBanner } from "../Banner/Banner";
import MiddleBannerImg1 from '../../assets/img/banner/size-extra-large-wide/banner-home-1-img-1-extra-large-wide.jpg'
import Navbar from "../../Navbar";
import ProductDetails from "./ProductDetails";
import Footer from "../../Footer";
import whatsappLogo from '../../assets/img/whatsapp.png';

import ServicesProduct1 from '../../assets/img/Services/TeliCommunication & PBX/product-gallery-large-1.jpg';
import ServicesProduct2 from '../../assets/img/Services/TeliCommunication & PBX/product-gallery-large-2.jpg';
import ServicesProduct3 from '../../assets/img/Services/TeliCommunication & PBX/product-gallery-large-3.jpg';
import ServicesProduct4 from '../../assets/img/Services/TeliCommunication & PBX/product-gallery-large-4.jpg';

const TeliCommunicationAndPBX = () => {
    return (
        <>
            <Navbar />
            <MiddleBanner MiddleBannerImg={MiddleBannerImg1} BannerHeading="TeliComm.. & PBX" />

            <div className="product-details mt-5">
                <div class="container">
                    <div class="row mt-5 pt-4">
                        <div class="col-md-5">
                            <div class="product-gallery-box m-b-60">
                                <div class="product-image--gallery overflow-hidden">
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct1} alt="" />
                                            {/* <img src="assets/img/product/gallery/gallery-large/product-gallery-large-2.jpg" alt="" /> */}
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct2} alt="" />
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct3} alt="" />
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct4} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-5">
                            <div class="product-details-box m-b-60 px-2">
                                <h5 className="h2 m-b-20">TeliCommunication & PBX</h5>

                                <div class="product__desc m-t-25 m-b-30">
                                    <p className='text-justify'>
                                        TeliCommunication and PBX work together to facilitate efficient communication within organizations. TeliCommunication technologies provide the means for external and internal communication, while PBX systems handle call management and routing within the organization, optimizing communication processes and enhancing productivity.
                                    </p>
                                </div>
                                <div class="product-var p-tb-30">
                                    <div class="product__stock m-b-20">
                                        <span class="product__stock--in"><i class="fas fa-check-circle"></i> IN STOCK</span>
                                    </div>
                                    {/* <div class="product-color product-var__item">
                                        <span class="product-var__text">Color</span>
                                        <div class="sidebar__color-filter ">
                                            <label class="product-color"><input name="large-product-color" type="radio" class="product-color-select" value="color-red" /><span></span></label>
                                            <label class="product-color" ><input name="large-product-color" type="radio" class="product-color-select" value="color-green" checked /><span></span></label>
                                            <label class="product-color" ><input name="large-product-color" type="radio" class="product-color-select" value="color-blue" /><span></span></label>
                                        </div>
                                    </div> */}

                                    {/* <div class="product-links">
                                        <a href="wishlist.html" class="link--gray link--icon-left shop__wishlist-icon m-b-5 m-r-20"><i class="ion-android-favorite-outline"></i>Add To Wishlist</a>
                                        <a href="compare.html" class="link--gray link--icon-left shop__wishlist-icon m-b-5"><i class="ion-ios-loop-strong"></i>Compare</a>
                                    </div> */}
                                    <div className='col-lg-12 col-md-12 d-none d-md-block d-lg-block'>

                                        <div class="nametag p-tb-10 ">
                                            <span className=''>Product:</span>
                                            <ul class="nametag__list d-flex justify-content-between">
                                                <li>business-grade,</li>
                                                <li>business telephone,</li>
                                                <li>telephone networks</li>
                                                <li>provider.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="nametag p-tb-10">
                                        <span>Share This Product :</span>
                                        <ul class="nametag__list d-flex justify-content-center">
                                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                            <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product-details-tab-area">
                <div class="container">
                    <div class="row pb-5">
                        <div class="col-12">
                            <ul class="tablist tablist--style-black tablist--style-title tablist--style-gap-70 nav">
                                <li><a class="nav-link active" data-bs-toggle="tab" href="#product-desc">Description</a></li>
                            </ul>
                        </div>
                        <div class="col-md-12">
                            <div class="product-details-tab-box">
                                <div class="tab-content">
                                    {/* Start Tab - Product Description */}
                                    <div class="tab-pane show active" id="product-desc">
                                        <div class="para__content">
                                            <h6 class="para__title">TeliCommunication And PBX :</h6>
                                            <p class="para__text">
                                                <p className="para__text">TeliCommunication and PBX (Private Branch Exchange) are two interconnected concepts that relate to telecommunications and business phone systems. Let's look at each of them separately: </p>
                                                <h6 class="para__title">TeliCommunication :</h6>
                                                <p className="para__text">TeliCommunication refers to the transmission of information, voice, and data over long distances using various telecommunications technologies and services. It encompasses a wide range of communication methods, including traditional landline phones, mobile phones, Voice over IP (VoIP) systems, video conferencing, messaging applications, and more.</p>
                                                <h6 class="para__title">Key components and features of TeliCommunication include:</h6>
                                                <ul class="para__list px-1">
                                                    <li className="py-1">Voice Communication: TeliCommunication facilitates voice-based communication through phone calls, enabling individuals and organizations to connect and have real-time conversations regardless of their physical location.</li>
                                                    <li className="py-1">Data Transmission: TeliCommunication involves the transfer of data over communication networks, allowing the exchange of files, documents, and other digital information between users or systems.</li>
                                                    <li className="py-1">Multimedia Communication: TeliCommunication enables the transmission of multimedia content such as video calls, video conferencing, and multimedia messaging, allowing individuals and businesses to engage in face-to-face communication remotely.</li>
                                                    <li className="py-1">Collaboration Tools: TeliCommunication often includes collaboration tools such as shared document editing, screen sharing, virtual whiteboards, and instant messaging platforms. These tools enhance teamwork and productivity by enabling real-time collaboration and communication among team members.</li>
                                                    <li className="py-1">Unified Communications: TeliCommunication may incorporate unified communications solutions, integrating various communication channels and applications into a single platform. This allows users to access and manage their voice calls, video calls, instant messages, emails, and other forms of communication from a unified interface.</li>
                                                </ul>

                                                <h6 class="para__title">PBX (Private Branch Exchange) :</h6>
                                                <p className="para__text">PBX, or Private Branch Exchange, is a private telephone switching system used within an organization to manage incoming and outgoing calls. It serves as an internal telephone network for the organization, providing a range of call management features and facilitating internal communication.</p>
                                                <h6 class="para__title">Key components and features of PBX include:</h6>
                                                <ul class="para__list px-1">
                                                    <li className="py-1 px-0">Call Routing: PBX systems handle incoming calls and route them to the appropriate extensions or departments within the organization. This ensures that calls are directed to the intended recipients efficiently.</li>
                                                    <li className="py-1 px-0">Call Management: PBX systems offer various call management features, including call transfer, call forwarding, call waiting, voicemail, and call recording. These features enhance call handling, improve customer service, and facilitate efficient internal communication.</li>
                                                    <li className="py-1 px-0">Extension Dialing: PBX systems enable users to dial short extension numbers to reach colleagues or departments within the organization, even if they are physically located in different areas or offices.</li>
                                                    <li className="py-1 px-0">VoIP Integration: Modern PBX systems often integrate with VoIP technology, allowing organizations to make and receive calls over IP networks. This enables cost savings, scalability, and flexibility in communication.</li>
                                                    <li className="py-1 px-0">Auto Attendant: PBX systems may include an automated attendant feature that greets callers and provides options for call routing, allowing callers to reach the desired destination without the need for operator assistance.</li>
                                                    <li className="py-1 px-0">vConference Calling: PBX systems often support conference calling, enabling multiple participants to join a single call, fostering collaboration and reducing the need for physical meetings.</li>
                                                    <li className="py-1 px-0">Scalability: PBX systems can be scaled to accommodate the growing needs of an organization, whether it involves adding new extensions, integrating remote offices, or expanding the system's capacity.</li>
                                                </ul>
                                            </p>
                                        </div>
                                        <p className="para__text">TeliCommunication and PBX work together to facilitate efficient communication within organizations. TeliCommunication technologies provide the means for external and internal communication, while PBX systems handle call management and routing within the organization, optimizing communication processes and enhancing productivity.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
            <div class="fixed-bottom right-100 p-3" style={{ zIndex: "6", left: "initial" }}>
                <a href="https://wa.me/919004392220?text=Hello How can I help you ?" target="_blank">
                    <img src={whatsappLogo} width="60" alt="aaaa" />
                </a>
            </div>
        </>
    )
};

export default TeliCommunicationAndPBX;