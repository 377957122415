import Navbar from "../../Navbar";
import 'bootstrap/dist/css/bootstrap.min.css';

import csm1 from '../../assets/img/icon/interview-icon.png'
import csm2 from '../../assets/img/icon/helpdesk-icon.png'
import csm3 from '../../assets/img/icon/success-green-check-mark-icon.png'
import csm4 from '../../assets/img/icon/cms/icon_about4.jpg'

import Icon1 from '../../assets/img/icon/environment-icon.png'
import Icon2 from '../../assets/img/icon/handshake-icon.png'
import Icon3 from '../../assets/img/icon/growth-icon.png'
import Icon4 from '../../assets/img/icon/data-science-icon.png'

import product1 from '../../assets/img/pageImage/cctv-camera-1.jpg';
import product2 from '../../assets/img//pageImage/fire-alarm-system.jpg';
import product3 from '../../assets/img//pageImage/server-desktop.png';
import product4 from '../../assets/img//pageImage/network-solution.jpg';

import AboutImg5 from '../../assets/img/pageImage/buessness-frendly-1.jpg'
import whatsappLogo from '../../assets/img/whatsapp.png';

// main css
import '../../assets/css/main.css'
// import '../../src/assets/css/main.css';

import Footer from "../../Footer";
import Banner, { MiddleBanner } from "../Banner/Banner";
import HeroSlider from "../slider/Slider";
import Latest from "./Latest";
import CompanyLogo from "../company/CompanyLogo";
import PopularProduct, { HomeHeaderContent } from "./HomeSection";
import { NavLink } from "react-router-dom";
import ServiceCard from "../about/AboutSection";



const Home = () => {
    return (
        <>
            <Navbar />
            <HeroSlider />

            <div className=" container py-4 mb-4">
                < HomeHeaderContent AboutImg={AboutImg5} HomeHeader="Buessness " HomeHeader1=" Friendly" HomeContent="We believe that every Product existing in digital and Hardware world is a result of an idea and every idea has a cause."
                    HomeInfo="We understand that buying electronic hardware products can be a daunting task, which is why we are committed to providing our customers with a hassle-free shopping experience. Our friendly and knowledgeable staff
                                are always on hand to help you with any questions or concerns you may have about our products, Infotec provide you with the latest and most advanced electronic hardware products.
                                 When you choose Infotec, you can trust that you're investing in top-of-the-line electronic hardware products that are built to perform and last. We prioritize quality assurance and only partner with
                                 reputable manufacturers known for their reliability and durability. By offering a comprehensive range of products, we aim to meet the diverse needs of individuals, professionals, and businesses alike. "
                />
            </div>

            {/* <!-- ::::::  Start CMS Section  ::::::  --> */}
            <div className="mt-2 m-b-50 gray-bg py-4">
                <div class="container">
                    <div class="row">
                        {/* <!-- Start Single CMS box --> */}


                        <div className="container mt-5">
                            <h1 className='text-center section-content__title'> OUR <span className="white-orange">MISSION</span> & VISSION</h1>
                            <div class="row container  d-flex">
                                <div class="col-lg-3 col-sm-6 col-12">
                                    <ServiceCard cardImg={csm1} cardTittle="CREATIVE ALWAYS" cardDescription="Thank you for considering our hardware electronic shop for your electronic hardware needs. We look forward to serving you and providing you with the best products and services in the industry." />
                                </div>

                                <div class="col-lg-3 col-sm-6 col-12">
                                    <ServiceCard cardImg={csm2} cardTittle="Customer SUPPORT" cardDescription="We also offer competitive pricing and special promotions to help you save money on your purchases. Our goal is to make sure that you not only get the best products but also the best value for your money." />
                                </div>
                                <div class="col-lg-3 col-sm-6 col-12">
                                    <ServiceCard cardImg={csm3} cardTittle="SUCCESS INTEGRATIONS" cardDescription="Infotec provide you with the latest and most advanced electronic hardware products. Our mission is to offer our customers the highest quality products and exceptional customer service." />
                                </div>
                                <div class="col-lg-3 col-sm-6 col-12">
                                    <ServiceCard cardImg={csm4} cardTittle="REAL-TIME EDITING" cardDescription="Our shop has been in business for several years, and we have become a trusted source for all things related to hardware electronics. We have a team of experienced professionals about technology." />
                                </div>
                            </div>
                            <div class="row container d-flex">
                                <div class="col-lg-3 col-sm-6 col-12">
                                    <ServiceCard cardImg={Icon1} cardTittle="environment" cardDescription="Our Staff Will Work with You to Identify Applicable Training Requirements for Compliance. We Provide a Wide Range of Specialized Health and Safety Consulting Services." />
                                </div>

                                <div class="col-lg-3 col-sm-6 col-12">
                                    <ServiceCard cardImg={Icon2} cardTittle="EXPRESS CUSTOMIZATION" cardDescription="At our shop, you will find a wide range of products, including computer hardware, mobile phones, cameras, and other electronic devices. We carefully select our products." />
                                </div>
                                <div class="col-lg-3 col-sm-6 col-12">
                                    <ServiceCard cardImg={Icon3} cardTittle="Company Groath" cardDescription="A growth company is one in which its business generates positive cash flows or earnings faster than the overall economy. paying out dividends to continue spurring growth." />
                                </div>
                                <div class="col-lg-3 col-sm-6 col-12">
                                    <ServiceCard cardImg={Icon4} cardTittle="data safely" cardDescription="Create customized list views to display records that meet your own criteria. You determine which records display by setting filters." />
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Single CMS box --> */}
                    </div>
                </div>
            </div>
            {/* <!-- ::::::  End CMS Section  ::::::  --> */}

            {/* <!-- ::::::  Start Product Style - Category Section  ::::::  --> */}
            <div class="product">
                <div className="container mb-2">
                    <div class="row">
                        <div class="col-12">
                            {/* <!-- Start Section Title --> */}
                            <div className="section-content mb-4 text-center">
                                <h5 class="section-content__title  py-4">Top <span className="white-orange"> Services</span></h5>
                            </div>
                            {/* <!-- End Section Title --> */}
                        </div>
                    </div>
                    <div class="row">
                        <div class="clearfix">
                            <PopularProduct cardImg={product1} cardTitle="CCTV SECURITY" />
                            <PopularProduct cardImg={product2} cardTitle="FIRE ALARM" />
                            <PopularProduct cardImg={product3} cardTitle="FIREWALL SOFTWARE" />
                            <PopularProduct cardImg={product4} cardTitle="NETWORK SOLUTIONS" />
                        </div>
                        <NavLink to="/services" className="col-md-2 mx-auto text-center my-5 btn btn-outline-dark white-orange rounded-5">MORE</NavLink>
                    </div>
                </div>
            </div>
            {/* <!-- ::::::  End Product Style - Category Section  ::::::  --> */}

            {/* <Latest />  z-index: 6; left: initial  */}

            <Banner />
            <CompanyLogo />
            <Footer />
            <div class="fixed-bottom right-100 p-3" style={{ zIndex: "6", left: "initial" }}>
                <a href="https://wa.me/919004392220?text=Hello How can I help you ?" target="_blank">
                    <img src={whatsappLogo} width="60" alt="aaaa" />
                </a>
            </div>
        </>
    )
}

export default Home;