import React from "react";


import BannerImg1 from '../../assets/img/banner/size-large-squar/square.jpg'
import BannerImg2 from '../../assets/img/banner/size-large-wide/firewall.jpg'
import BannerImg3 from '../../assets/img/banner/size-default-squar/banner_2.jpg'
import BannerImg4 from '../../assets/img/banner/size-default-squar/banner_3.png'



const Banner = () => {
    return (
        <>
            {/* ::::::  Satrt banner Section  ::::::  */}

            <div class="banner m-t-50 m-0 p-0 ">
                <div class="">
                    <div class="d-flex flex-lg-row flex-column">
                        <div class="banner__box">
                            <div class="banner__box--single banner__box--single-text-style-1 pos-relative">
                                <a href="#" class="banner__link glossy-flash-animate">
                                    <img src={BannerImg1} alt="" class="banner__img" />
                                    <div class="banner__content banner__content--top pos-absolute text-center">
                                        <h5 class="banner__title banner__title--small  white-color font--semi-bold m-b-15 text-uppercase">20% Off ! Limited week deal</h5>
                                        <h2 class="banner__title banner__title--large white-color font--bold m-b-35">Video Conference System</h2>
                                    </div>
                                </a>
                                {/* <a href="#" class="btn btn--box btn--small btn--gray btn--gray-hover-red font--bold text-uppercase pos-absolute banner--btn-1">Discover Now</a> */}
                            </div>
                        </div>
                        <div class="banner__box">
                            <div class="banner__box--single pos-relative">
                                <a href="#" class="banner__link glossy-flash-animate">
                                    <img src={BannerImg2} alt="" class="banner__img" height={"406px !important"} />
                                    {/* <div class="banner__content banner__content--center pos-absolute">
                                        <h6 class="banner__title banner__title--tiny  white-color font--semi-bold m-b-15 text-uppercase">Electronics</h6>
                                        <h4 class="banner__title banner__title--medium  white-color font--bold m-b-35">Wireless Black <br />
                                            Headphone</h4>
                                    </div> */}
                                </a>
                                {/* <a href="#" class="btn btn--underline btn--underline-white font--bold text-uppercase pos-absolute banner--btn-2">Discover Now</a> */}
                            </div>
                            <div class="banner__box d-flex flex-sm-row flex-column">
                                <div class="banner__box--single pos-relative">
                                    <a href="#" class="banner__link glossy-flash-animate">
                                        <img src={BannerImg3} alt="" class="banner__img" />
                                    </a>
                                </div>

                                <div class="banner__box--single pos-relative">
                                    <a href="#" class="banner__link glossy-flash-animate">
                                        <img src={BannerImg4} alt="" class="banner__img" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ::::::  End banner Section  ::::::  */}
        </>
    )
};

const MiddleBanner = (prop) => {

    return (
        <>
            {/* ::::::  Start banner Section  ::::::  */}
            <div class="" style={{ marginTop: "80px" }}>
                <div class="d-flex flex-lg-row flex-column">
                    <div class="banner__box">
                        {/* Start Single Banner Item */}
                        <div class="banner__box--single banner__box--single-text-style-1 pos-relative">
                            <a class="banner__link glossy-flash-animate">
                                <img src={prop.MiddleBannerImg} alt="" className="banner__img img-fluid" />
                                <div class="banner__content banner__content--top pos-absolute text-center d-flex justify-content-center">
                                    <h2 className="banner__title banner__title--large white-orange font--bold">{prop.BannerHeading}</h2>
                                    {/* <h5 class="banner__title banner__title--small  white-color font--semi-bold m-b-10 text-uppercase">{prop.Taggline}</h5> */}
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* ::::::  End banner Section  ::::::  */}

        </>
    )
};



export default Banner;
export { MiddleBanner };

