

import React from "react";
import { MiddleBanner } from "../Banner/Banner";
import MiddleBannerImg1 from '../../assets/img/banner/size-extra-large-wide/banner-home-1-img-1-extra-large-wide.jpg'
import Navbar from "../../Navbar";
import ProductDetails from "./ProductDetails";
import Footer from "../../Footer";

import ServicesProduct1 from '../../assets/img/Services//MobileNetworkBooster/product-gallery-large-1.jpg';
import ServicesProduct2 from '../../assets/img/Services/MobileNetworkBooster/product-gallery-large-2.jpg';
import ServicesProduct3 from '../../assets/img/Services/MobileNetworkBooster/product-gallery-large-3.jpg';
import ServicesProduct4 from '../../assets/img/Services/MobileNetworkBooster/product-gallery-large-4.jpg';
import whatsappLogo from '../../assets/img/whatsapp.png';

const MobileNetworkBooster = () => {
    return (
        <>
            <Navbar />
            <MiddleBanner MiddleBannerImg={MiddleBannerImg1} BannerHeading="Mobile Network Booster" />

            <div className="product-details mt-5">
                <div class="container">
                    <div class="row mt-5 pt-4">
                        <div class="col-md-5">
                            <div class="product-gallery-box m-b-60">
                                <div class="product-image--gallery overflow-hidden">
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct1} alt="" />
                                            {/* <img src="assets/img/product/gallery/gallery-large/product-gallery-large-2.jpg" alt="" /> */}
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct2} alt="" />
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct3} alt="" />
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct4} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-5">
                            <div class="product-details-box m-b-60 px-2">
                                <h5 className="h2 m-b-20">Mobile Network Booster</h5>

                                <div class="product__desc m-t-25 m-b-30">
                                    <p className='text-justify'>
                                        Mobile network boosters are particularly useful in areas with poor cellular coverage, such as remote locations, basements, or buildings with thick walls that block signals. They can improve call reliability, eliminate dropped calls, and enhance data speeds, enabling users to enjoy uninterrupted voice calls, faster internet browsing, smoother streaming, and better overall mobile connectivity.
                                    </p>
                                </div>
                                <div class="product-var p-tb-30">
                                    <div class="product__stock m-b-20">
                                        <span class="product__stock--in"><i class="fas fa-check-circle"></i> IN STOCK</span>
                                    </div>
                                    {/* <div class="product-color product-var__item">
                                        <span class="product-var__text">Color</span>
                                        <div class="sidebar__color-filter ">
                                            <label class="product-color"><input name="large-product-color" type="radio" class="product-color-select" value="color-red" /><span></span></label>
                                            <label class="product-color" ><input name="large-product-color" type="radio" class="product-color-select" value="color-green" checked /><span></span></label>
                                            <label class="product-color" ><input name="large-product-color" type="radio" class="product-color-select" value="color-blue" /><span></span></label>
                                        </div>
                                    </div> */}

                                    {/* <div class="product-links">
                                        <a href="wishlist.html" class="link--gray link--icon-left shop__wishlist-icon m-b-5 m-r-20"><i class="ion-android-favorite-outline"></i>Add To Wishlist</a>
                                        <a href="compare.html" class="link--gray link--icon-left shop__wishlist-icon m-b-5"><i class="ion-ios-loop-strong"></i>Compare</a>
                                    </div> */}
                                    <div className='col-lg-12 col-md-12 d-none d-md-block d-lg-block'>

                                        <div class="nametag p-tb-10 ">
                                            <span className=''>Product:</span>
                                            <ul class="nametag__list d-flex justify-content-between">
                                                <li>WiFi Extender,</li>
                                                <li>Signal Booster,</li>
                                                <li>Internet Booster,</li>
                                                <li>with Patch Panel.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="nametag p-tb-10">
                                        <span>Share This Product :</span>
                                        <ul class="nametag__list d-flex justify-content-center">
                                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                            <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product-details-tab-area">
                <div class="container">
                    <div class="row pb-5">
                        <div class="col-12">
                            <ul class="tablist tablist--style-black tablist--style-title tablist--style-gap-70 nav">
                                <li><a class="nav-link active" data-bs-toggle="tab" href="#product-desc">Description</a></li>
                            </ul>
                        </div>
                        <div class="col-md-12">
                            <div class="product-details-tab-box">
                                <div class="tab-content">
                                    {/* Start Tab - Product Description */}
                                    <div class="tab-pane show active" id="product-desc">
                                        <div class="para__content">
                                            <h6 class="para__title">Mobile Network Booster :</h6>
                                            <p class="para__text">
                                                <p className="para__text">A mobile network booster, also known as a cellular signal booster or repeater, is a device designed to improve the strength and quality of cellular signals in areas with weak or unreliable mobile network coverage. It enhances the signal reception and transmission capabilities of mobile devices, such as smartphones, tablets, or mobile routers, allowing users to enjoy better call quality, faster data speeds, and improved overall connectivity.
                                                </p>
                                                <h6 class="para__title">Key components and features of a mobile network booster include :</h6>
                                                <ul class="para__list px-1">
                                                    <li className="py-1 px-0">External Antenna: The mobile network booster typically includes an external antenna that is installed outside the building or vehicle. The antenna is designed to capture weak cellular signals from the nearest cell tower and transmit them to the booster unit.</li>
                                                    <li className="py-1 px-0">Booster Unit: The booster unit is the central component of the system. It receives the weak cellular signals from the external antenna, amplifies them, and rebroadcasts them inside the building or vehicle.</li>
                                                    <li className="py-1 px-0">Internal Antenna: The internal antenna is installed inside the building or vehicle and broadcasts the amplified signals to the surrounding area. It ensures that the improved signal strength is distributed evenly for optimal coverage.</li>
                                                    <li className="py-1 px-0">Signal Amplification: The booster unit amplifies the weak cellular signals received from the external antenna, increasing their strength and improving signal quality. This allows mobile devices within the boosted area to experience stronger and more reliable network connections.</li>
                                                    <li className="py-1 px-0">Multiple Frequency Support: Mobile network boosters are designed to support multiple cellular frequencies, including those used by different carriers. This ensures compatibility with various network technologies, such as 2G, 3G, 4G LTE, and in some cases, 5G.</li>
                                                    <li className="py-1 px-0">Coverage Area: The coverage area of a mobile network booster depends on factors such as the strength of the incoming signal, the booster's amplification power, and the internal antenna's broadcasting range. Booster models are available for different coverage areas, ranging from small rooms to large buildings or vehicles.</li>
                                                    <li className="py-1 px-0">Installation and Setup: Mobile network boosters require proper installation and setup to ensure optimal performance. This typically involves mounting the external antenna in a location with the best possible signal reception, connecting it to the booster unit, and placing the internal antenna in a central location for even signal distribution.</li>
                                                </ul>
                                            </p>

                                        </div>
                                        <p className="para__text">Mobile network boosters are particularly useful in areas with poor cellular coverage, such as remote locations, basements, or buildings with thick walls that block signals. They can improve call reliability, eliminate dropped calls, and enhance data speeds, enabling users to enjoy uninterrupted voice calls, faster internet browsing, smoother streaming, and better overall mobile connectivity.</p>
                                        <p className="para__text">It's important to note that the use of mobile network boosters should comply with local regulations and carrier guidelines. Additionally, the effectiveness of a mobile network booster depends on the existing signal strength in the area and the quality of the booster system installed.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
            <div class="fixed-bottom right-100 p-3" style={{ zIndex: "6", left: "initial" }}>
                <a href="https://wa.me/919004392220?text=Hello How can I help you ?" target="_blank">
                    <img src={whatsappLogo} width="60" alt="aaaa" />
                </a>
            </div>
        </>
    )
};

export default MobileNetworkBooster;