import React from "react";
// import sliderImg1 from '../../assets/img/hero/hero-home-1-img-1.jpg';
// import sliderImg2 from '../../assets/img/hero/hero-home-1-img-2.jpg';
// import productImg1 from '../../assets/img/blog/feed/blog-feed-home-1-img-1.jpg'
// import productImg2 from '../../assets/img/blog/feed/blog-feed-home-1-img-2.jpg'
// import productImg3 from '../../assets/img/blog/feed/blog-feed-home-1-img-3.jpg'
// import productImg4 from '../../assets/img/blog/feed/blog-feed-home-1-img-4.jpg'
// import productImg5 from '../../assets/img/blog/feed/blog-feed-home-1-img-5.jpg'
// import productImg6 from '../../assets/img/blog/feed/blog-feed-home-1-img-6.jpg'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../..//assets/css/main.css';
import '../../assets/css/vendor/jquery-ui.min.css'
import '../../assets/css/vendor/fontawesome.css'
import '../../assets/css/vendor/ionicons.min.css'
import '../../assets/css/vendor/bootstrap.min.css'
import { NavLink } from "react-router-dom";



const LatestProduct = (prop) => {

    return (
        <>
            <div className="px-1">
                <div className="card bg-transparent mb-4 pb-2 mx-auto">
                    <img class="card-img-top mx-auto d-block glossy-flash-animate img-fluid" src={prop.cardImg} alt="" style={{ backgroundSize: "Cover" }} />
                    <div class="card-body">
                        <h5 class="card-title">{prop.cardDate}</h5>
                        <h5 class="card-title ">{prop.cardHeading}</h5>
                        <p class="card-text text-justify " style={{ fontSize: "14px" }}>{prop.cardContent}</p>
                        {/* <a href="#" className="text-black text-decoration-none">{prop.cardLink}<hr className="w-25 p-0 m-0"></hr></a> */}
                        <NavLink to="/services" className=" text-decoration-none white-orange" >{prop.cardLink}<hr className="w-25 p-0 m-0"></hr></NavLink>
                    </div>
                </div>
            </div>
        </>

    );

}

export default LatestProduct;