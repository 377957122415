

import React from "react";
import { MiddleBanner } from "../Banner/Banner";
import MiddleBannerImg1 from '../../assets/img/banner/size-extra-large-wide/banner-home-1-img-1-extra-large-wide.jpg'
import Navbar from "../../Navbar";
import ProductDetails from "./ProductDetails";
import Footer from "../../Footer";


import ServicesProduct1 from '../../assets/img/Services/CCTV and Security/product-gallery-large-1.jpg';
import ServicesProduct2 from '../../assets/img/Services/CCTV and Security/product-gallery-large-2.jpg';
import ServicesProduct3 from '../../assets/img/Services/CCTV and Security/product-gallery-large-3.jpg';
import ServicesProduct4 from '../../assets/img/Services/CCTV and Security/product-gallery-large-4.jpg';
import whatsappLogo from '../../assets/img/whatsapp.png';


const CCTVandSecurity = () => {
    return (
        <>

            <Navbar />
            <MiddleBanner MiddleBannerImg={MiddleBannerImg1} BannerHeading="CCTV & Security" />

            <div className="product-details mt-5">
                <div class="container">
                    <div class="row mt-5 pt-4">
                        <div class="col-md-5">
                            <div class="product-gallery-box m-b-60">
                                <div class="product-image--gallery overflow-hidden">
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct1} alt="" />
                                            {/* <img src="assets/img/product/gallery/gallery-large/product-gallery-large-2.jpg" alt="" /> */}
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct2} alt="" />
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct3} alt="" />
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct4} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-5">
                            <div class="product-details-box m-b-60 px-2">
                                <h5 className="h2 m-b-20">CCTV & Security</h5>

                                <div class="product__desc m-t-25 m-b-30">
                                    <p className='text-justify'>Overall, CCTV security systems provide a valuable tool for enhancing safety, deterring criminal activities, and enabling effective monitoring and response. The technology continues to advance, with features like high-definition video, remote access, intelligent analytics, and cloud-based storage, further improving the effectiveness and capabilities of CCTV security solutions.
                                    </p>
                                </div>
                                <div class="product-var p-tb-30">
                                    <div class="product__stock m-b-20">
                                        <span class="product__stock--in"><i class="fas fa-check-circle"></i> IN STOCK</span>
                                    </div>
                                    {/* <div class="product-color product-var__item">
                                        <span class="product-var__text">Color</span>
                                        <div class="sidebar__color-filter ">
                                            <label class="product-color"><input name="large-product-color" type="radio" class="product-color-select" value="color-red" /><span></span></label>
                                            <label class="product-color" ><input name="large-product-color" type="radio" class="product-color-select" value="color-green" checked /><span></span></label>
                                            <label class="product-color" ><input name="large-product-color" type="radio" class="product-color-select" value="color-blue" /><span></span></label>
                                        </div>
                                    </div> */}

                                    {/* <div class="product-links">
                                        <a href="wishlist.html" class="link--gray link--icon-left shop__wishlist-icon m-b-5 m-r-20"><i class="ion-android-favorite-outline"></i>Add To Wishlist</a>
                                        <a href="compare.html" class="link--gray link--icon-left shop__wishlist-icon m-b-5"><i class="ion-ios-loop-strong"></i>Compare</a>
                                    </div> */}
                                    <div className='col-lg-12 col-md-12 d-none d-md-block d-lg-block'>

                                        <div class="nametag p-tb-10 ">
                                            <span className=''>Product:</span>
                                            <ul class="nametag__list d-flex justify-content-between">
                                                <li>surveillance,</li>
                                                <li> security cameras,</li>
                                                <li> Wi-Fi Smart Camera,</li>
                                                <li>Spy Cameras.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="nametag p-tb-10">
                                        <span>Share This Product :</span>
                                        <ul class="nametag__list d-flex justify-content-center">
                                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                            <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product-details-tab-area">
                <div class="container">
                    <div class="row pb-5">
                        <div class="col-12">
                            <ul class="tablist tablist--style-black tablist--style-title tablist--style-gap-70 nav">
                                <li><a class="nav-link active" data-bs-toggle="tab" href="#product-desc">Description</a></li>
                                {/* <li><a class="nav-link" data-bs-toggle="tab" href="#product-dis">Product Details</a></li> */}
                                {/* <li><a class="nav-link" data-bs-toggle="tab" href="#product-review">Reviews</a></li> */}
                            </ul>
                        </div>
                        <div class="col-md-12">
                            <div class="product-details-tab-box">
                                <div class="tab-content">
                                    {/* Start Tab - Product Description */}
                                    <div class="tab-pane show active" id="product-desc">
                                        <div class="para__content">
                                            <h6 class="para__title">CCTV & Security:</h6>
                                            <p class="para__text">
                                                <p className="para__text">CCTV (Closed-Circuit Television) security refers to the use of video surveillance cameras and related technologies to monitor and protect physical spaces, assets,
                                                    and individuals. It is a widely used security measure in various settings, including public areas, businesses, residential properties, and institutions. CCTV systems provide real-time monitoring,
                                                    recording, and analysis of video footage to enhance safety, deter crime, and aid in investigations.
                                                </p>
                                                <h6 class="para__title">Key components and features of CCTV security include:</h6>
                                                <ul class="para__list px-1">
                                                    <li className="py-1 px-0">1.Cameras: CCTV systems consist of cameras strategically positioned to capture video footage of specific areas. These cameras can be fixed or equipped with pan, tilt, and zoom (PTZ) capabilities to provide a wider coverage range and the ability to focus on specific points of interest. Cameras can be indoor or outdoor, and their selection depends on the environment and purpose of surveillance.</li>
                                                    <li className="py-1 px-0">2.Video Management System (VMS): A VMS is the central software component that manages and controls the CCTV system. It enables live video monitoring, recording, playback, and video analysis. The VMS may include features like motion detection, facial recognition, object tracking, and video analytics to identify specific events or behaviors.</li>
                                                    <li className="py-1 px-0">3.Recording and Storage: CCTV systems record video footage either continuously or triggered by motion detection or specific events. The recorded footage is stored on digital video recorders (DVRs), network video recorders (NVRs), or cloud-based storage solutions.</li>
                                                    <li className="py-1 px-0">4.Monitoring and Control: CCTV security allows real-time monitoring of video feeds, typically from a centralized control room or through remote access using computers, smartphones, or tablets. Operators can monitor multiple camera feeds simultaneously and respond promptly to incidents or suspicious activities.</li>
                                                    <li className="py-1 px-0">5.Video Analytics: Advanced CCTV systems incorporate video analytics technologies to extract meaningful information from the video footage. This includes detecting unusual behavior, identifying objects or individuals of interest, counting people or vehicles, and generating alerts based on predefined rules or patterns.</li>
                                                    <li className="py-1 px-0">6.Compliance and Privacy Considerations: CCTV security must adhere to legal and privacy regulations governing the use and storage of video surveillance data. Organizations need to comply with applicable laws and ensure that privacy rights of individuals are respected, including proper signage and notification about surveillance activities.</li>
                                                </ul>
                                            </p>

                                        </div>
                                        <p className="para__text">Overall, CCTV security systems provide a valuable tool for enhancing safety, deterring criminal activities, and enabling effective monitoring and response. The technology continues to advance, with features like high-definition video, remote access, intelligent analytics, and cloud-based storage, further improving the effectiveness and capabilities of CCTV security solutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
            <div class="fixed-bottom right-100 p-3" style={{ zIndex: "6", left: "initial" }}>
                <a href="https://wa.me/919004392220?text=Hello How can I help you ?" target="_blank">
                    <img src={whatsappLogo} width="60" alt="aaaa" />
                </a>
            </div>
        </>
    )
};

export default CCTVandSecurity;