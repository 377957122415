import React from 'react'
import Footer from '../../Footer'
import Navbar from '../../Navbar'
import ServiceCard, { ServiceContent, ServiceSlider } from './ServicesSection'
import csm1 from '../../assets/img/icon/cms/server-desktop.png'
import csm2 from '../../assets/img/icon/cms/firwall-software.png'
import csm3 from '../../assets/img/icon/cms/network-solution.png'
import csm4 from '../../assets/img/icon/cms/network-booster.png'
import csm5 from '../../assets/img/icon/cms/teliCommunication.png'
import csm6 from '../../assets/img/icon/cms/video-confrancing-1.png'
import csm7 from '../../assets/img/icon/cms/cctv-icon-1.png'
import csm8 from '../../assets/img/icon/cms/Fire-Alarm.png'

import AboutImg1 from '../../assets/img/pageImage/hardware-3.webp'
import AboutImg2 from '../../assets/img/pageImage/infotech.jpg'
import AboutImg3 from '../../assets/img/pageImage/camera-install.jpg'
import AboutImg4 from '../../assets/img/pageImage/network-commu.jpg'

import MiddleBannerImg1 from '../../assets/img/banner/size-extra-large-wide/banner-home-1-img-1-extra-large-wide.jpg'

import ServicesProduct1 from '../../assets/img/product/gallery/gallery-large/product-gallery-large-1.jpg';
import ServicesProduct2 from '../../assets/img/product/gallery/gallery-large/product-gallery-large-2.jpg';
import ServicesProduct3 from '../../assets/img/product/gallery/gallery-large/product-gallery-large-3.jpg';
import ServicesProduct4 from '../../assets/img/product/gallery/gallery-large/product-gallery-large-4.jpg';
import whatsappLogo from '../../assets/img/whatsapp.png';




import CompanyLogo from '../company/CompanyLogo'
import { AboutContent } from '../about/AboutSection'
import { MiddleBanner } from '../Banner/Banner'
import Latest from '../homePage/Latest'

const Services = () => {
    return (
        <div>
            <Navbar />
            <MiddleBanner MiddleBannerImg={MiddleBannerImg1} BannerHeading="Our Services" />

            <div className=' py-2'>
                <div class="container">
                    {/* <h1 className='text-center mt-4'>Our Services</h1> */}
                    <h1 className='text-center mt-5 section-content__title'>OUR <span className='white-orange'>SERVICES</span> </h1>
                    <div class="row container mb-5">
                        <div class="col-lg-3 col-sm-6 col-12">
                            <ServiceCard cardImg={csm1} cardTittle="Server & Desktop" carddes="Ultimately, there are cases when a desktop can be used as a server in the interim, but it shouldn't be a long-term solution. With the popularity of cloud-based collaboration rising the last several years," />
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <ServiceCard cardImg={csm2} cardTittle="Firewall & Software" carddes="Has the product been a good partner in doing business, Availability, Intrusion Prevention, and Continuous Analysis are the top four factors that positively impact user satisfaction for Firewall Software products." />
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <ServiceCard cardImg={csm3} cardTittle="Network Solutions" carddes="Network Solutions offers everything you need to get online quickly. From website development to optimization, social media, online advertising and more, we'll have your business online in no time." />
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <ServiceCard cardImg={csm4} cardTittle="Mobile Network Booster" carddes="Our Multi Network Mobile Signal Boosters are best for Calling & Data surfing, They support all networks all over India, even the newly launched Jio Network." />
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <ServiceCard cardImg={csm5} cardTittle="TeliCommunication & PBX" carddes="PBX is a telephone system that allows calls between users on separate lines while enabling external calls to show as one number." />
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <ServiceCard cardImg={csm6} cardTittle="Video Conferencing System" carddes="Browse video conferencing products including conference cameras, room solutions, webcams, headsets, collaboration tools, and accessories." />
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <ServiceCard cardImg={csm7} cardTittle="CCTV & Security" carddes="High resolution cameras for 24x7 monitoring · People counting cameras at entrance points for footfall count and to know the trend of business" />
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <ServiceCard cardImg={csm8} cardTittle="Fire Alarm System" carddes="A fire alarm system is a set of devices that detect and alert people to the presence of smoke, fire, carbon monoxide, or other fire-related emergencies." />
                        </div>
                    </div>
                </div>
            </div>

            {/* <ServiceSlider /> */}
            <Latest />

            <div>
                <div class="py-4">
                    <ServiceContent
                    
                    AboutImgSec1={AboutImg2} AboutImgHeaderSec1="Server & Desktop"
                    AboutImgInfoSec1="While server management focuses on centralized administration, security, and performance optimization, desktop management focuses on individual user environments, productivity, and user support. Effective
                    management of both servers and desktops requires a combination of technical expertise, proactive monitoring, regular
                    maintenance, and user support."
                    
                    AboutImgSec2={AboutImg1} AboutImgHeaderSec2="Hardware Solution" AboutImgInfoSec2="Hardware and solutions refer to the comprehensive set of technologies, strategies, and 
                    services implemented to create, manage, and optimize computer networks for organizations. These networks are designed to facilitate efficient communication, data sharing,
                     and resource utilization among devices, systems, and users within an organization or across multiple locations."
                    />
                    <ServiceContent

                    AboutImgSec1={AboutImg3} AboutImgHeaderSec1="CCTV Services"
                    AboutImgInfoSec1="CCTV (Closed-Circuit Television) security refers to the use of video surveillance cameras and related technologies to monitor and protect physical spaces, assets,
                    and individuals. It is a widely used security measure in various settings, including public areas, businesses, residential properties, and institutions. CCTV systems provide real-time monitoring,
                    recording, and analysis of video footage to enhance safety, deter crime, and aid in investigations."

                    AboutImgSec2={AboutImg4} AboutImgHeaderSec2="Network And Telicom.." AboutImgInfoSec2="Network and telecommunications are essential components of modern communication systems. They enable the exchange
                     of information and data across various devices and locations, facilitating seamless connectivity and collaboration. Whether it's a small-scale local network or a large-scale telecommunications infrastructure, 
                     these technologies play a crucial role in our interconnected world."
                    
                    />

                </div>
            </div>
            {/* <CompanyLogo /> */}
            <Footer />
            <div class="fixed-bottom right-100 p-3" style={{ zIndex: "6", left: "initial" }}>
                <a href="https://wa.me/919004392220?text=Hello How can I help you ?" target="_blank">
                    <img src={whatsappLogo} width="60" alt="aaaa" />
                </a>
            </div>
        </div>
    )
}

export default Services;