import React from 'react'
import Footer from '../../Footer'
import Navbar from '../../Navbar'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/main.css'
import { CompanyLogos } from '../about/AboutSection';
import CompanyLogo from '../company/CompanyLogo';
import MiddleBannerImg1 from '../../assets/img/banner/size-extra-large-wide/banner-home-1-img-1-extra-large-wide.jpg'
import { MiddleBanner } from '../Banner/Banner';
import whatsappLogo from '../../assets/img/whatsapp.png';


export default function ContactUs() {
    return (
        <>
            <div>
                <Navbar />
                <MiddleBanner MiddleBannerImg={MiddleBannerImg1} BannerHeading="Contact Us" />

                <main id="" className="container mx-auto">
                    <div className=" my-5">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 rounded-2">
                                <div id="map">
                                    <iframe className='fluid' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.6946666138997!2d73.08937247507986!3d19.20853398202347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be79588eab22513%3A0x45e1522c1e0230c3!2sPandurang%20Wadi%20Rd%2C%20Dombivli%20East%2C%20Dombivli%2C%20Maharashtra%20421201!5e0!3m2!1sen!2sin!4v1683725186407!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-12 col-md-6">
                                <div class="contact-info-wrap gray-bg m-t-40">
                                    <h2 className='text-center py-5'>Contact Us</h2>
                                    <div class="single-contact-info">
                                        <div class="contact-icon">
                                            <i class="fas fa-phone-alt"></i>
                                        </div>
                                        <div class="contact-info-dec">
                                            Viraj Telekar
                                            <br></br>
                                            09930377401
                                        </div>
                                    </div>
                                    <div class="single-contact-info">
                                        <div class="contact-icon">
                                            <i class="fas fa-globe-asia"></i>
                                        </div>
                                        <div class="contact-info-dec">
                                            <a href="emailto:sales@infotecsolution.in">sales@infotecsolution.in</a>
                                            <a href="https://infotecsolution.in/">infotecsolution.in</a>
                                        </div>
                                    </div>
                                    <div class="single-contact-info">
                                        <div class="contact-icon">
                                            <i class="fas fa-map-marker-alt"></i>
                                        </div>
                                        <div class="contact-info-dec">
                                            <span>Address goes here,</span>
                                            <span>A7,Virat Balaji darshan Patharli Road Gogras Wadi Dombivali East.</span>
                                        </div>
                                    </div>
                                    <div className="contact-social m-t-40">
                                        <div class="section-content">
                                            <h5 class="section-content__title">Follow Us</h5>
                                        </div>
                                        <div class="social-link m-t-30 text-center">
                                            <ul className="footer__social-nav p-0 d-flex justify-content-center">
                                                <li class="footer__social-list"><a href="#"
                                                    class="footer__social-link"><i
                                                        class="fab fa-facebook-f"></i></a></li>
                                                <li class="footer__social-list"><a href="#"
                                                    class="footer__social-link"><i
                                                        class="fab fa-twitter"></i></a></li>
                                                <li class="footer__social-list"><a href="#"
                                                    class="footer__social-link"><i
                                                        class="fab fa-youtube"></i></a></li>
                                                <li class="footer__social-list"><a href="#"
                                                    class="footer__social-link"><i
                                                        class="fab fa-google-plus-g"></i></a></li>
                                                <li class="footer__social-list"><a href="#"
                                                    class="footer__social-link"><i
                                                        class="fab fa-instagram"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-6">
                                <div class="contact-form gray-bg m-t-40">
                                    <div class="section-content py-4 ">
                                        <h5 class="section-content__title">Get In <span className='white-orange'> Touch</span></h5>
                                    </div>
                                    <form class="contact-form-style" id="contact-form" action="#" method="POST">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-box__single-group">
                                                    <input type="text" placeholder="Name" required />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-box__single-group">
                                                    <input type="email" placeholder="Email" required />
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="form-box__single-group">
                                                    <input type="text" placeholder="Subject" required />
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="form-box__single-group">
                                                    <textarea rows="8" placeholder="Your Messege" required></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <button className="btn px-4 text-center btn-outline-light btn-dark btn--uppercase font--bold m-t-30" type="submit">Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                {/* <CompanyLogo /> */}

                <Footer />
            </div>

            <div class="fixed-bottom right-100 p-3" style={{ zIndex: "6", left: "initial" }}>
                <a href="https://wa.me/919004392220?text=Hello How can I help you ?" target="_blank">
                    <img src={whatsappLogo} width="60" alt="aaaa" />
                </a>
            </div>
        </>
    )
}