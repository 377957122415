import Carousel from 'react-bootstrap/Carousel';
import sliderImg1 from '../../assets/img/hero/hero-home-1-img-1.jpg';
import sliderImg4 from '../../assets/img/hero/hero-home-1-img-4.png';
import sliderImg3 from '../../assets/img/hero/hero-home-1-img-3.png';
import sliderImg2 from '../../assets/img/hero/video_conference.png';


import 'bootstrap/dist/css/bootstrap.min.css';
import '../..//assets/css/main.css';
import '../../assets/css/vendor/jquery-ui.min.css'
import '../../assets/css/vendor/fontawesome.css'
import '../../assets/css/vendor/ionicons.min.css'
import '../../assets/css/vendor/bootstrap.min.css'
import { NavLink } from 'react-router-dom';


function HeroSlider() {
    return (
        <>
            <Carousel className="hero text-dark hero--1 slider-dot-fix slider-dot slider-dot-fix slider-dot--center slider-dot-size--medium slider-dot-circle  slider-dot-style--fill slider-dot-style--fill-white-active-golden dot-gap__X--10" style={{ marginTop: "75px" }}>

           {/*  <Carousel.Item className='hero-slider'>
                    <img className="d-block w-100 img-fluid" src={sliderImg1} alt="First slide" />
                    <Carousel.Caption className='hero__content'>
                        <div class="col-8 col-lg-8 offset-lg-1 col-xl-6">
                            <div class="hero__content--inner">
                                <h1 class="hero-title-1 title title--large title--white text-uppercase textone">CCTV</h1>
                                <h1 class="hero-title-2 title title--large title--white font--bold text-uppercase textone">Installation</h1>
                                <h6 class="hero-title-3 title  title--white pos-relative texttwo">
                                    Infotech is an CCTV Installation<br className='py-2'></br> outsourcing company that provides
                                    <br className='py-2'></br>services for various businesses.
                                </h6>
                                <NavLink to="/contact" className="col-3 mx-auto btn btn-outline-light btn-danger text-uppercase d-none d-xl-block d-lg-block">
                                    Contact Now
                                </NavLink>
                            </div>
                        </div>

                    </Carousel.Caption>
                </Carousel.Item> */}


                <Carousel.Item className='hero-slider'>
                    <img className="d-block w-100 img-fluid" src={sliderImg4} alt="First slide" />
                    <Carousel.Caption className='hero__content'>
                        <div class="col-8 col-lg-8 offset-lg-1 col-xl-6">
                            <div class="hero__content--inner">
                                <h1 class="hero-title-1 title title--large title--white text-uppercase textone">Networking</h1>
                                <h1 class="hero-title-2 title title--large title--white font--bold text-uppercase textone">Solution</h1>
                                <h6 class="hero-title-3 title  title--white pos-relative texttwo">
                               A simple exchange of <br className='py-2'></br> information or services, allows devices to be 
                                    <br className='py-2'></br> connected to each other on network.
                                </h6>
                                {/* <NavLink to='/' className="btn btn-light rounded-0 font--bold text-uppercase" >Shop now</NavLink> */}
                                <NavLink to="/contact" className="col-3 mx-auto btn btn-outline-light btn-danger text-uppercase d-none d-xl-block d-lg-block">
                                    Contact Now
                                </NavLink>
                            </div>
                        </div>

                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item className='hero-slider'>
                    <img className="d-block w-100 img-fluid" src={sliderImg2} alt="First slide" />
                    <Carousel.Caption className='hero__content'>
                        <div class="col-8 col-lg-8 offset-lg-1 col-xl-6">
                            <div class="hero__content--inner">
                                <h1 class="hero-title-1 title title--large title--white text-uppercase textone">Video Conference</h1>
                                <h1 class="hero-title-2 title title--large title--white font--bold text-uppercase textone">System</h1>
                                <h6 class="hero-title-3 title  title--white pos-relative texttwo">
                                The video conferencing series includes <br className='py-2'></br> a full range of products such as USB cameras,<br className='py-2'></br> conference cameras, mic & speakers,
                                    <br className='py-2'></br> video conferencing systems, etc.
                                </h6>
                                {/* <NavLink to='/' className="btn btn-light rounded-0 font--bold text-uppercase" >Shop now</NavLink> */}
                                <NavLink to="/contact" className="col-3 mx-auto btn btn-outline-light btn-danger text-uppercase d-none d-xl-block d-lg-block">
                                    Contact Now
                                </NavLink>
                            </div>
                        </div>

                    </Carousel.Caption>
                </Carousel.Item>

                {/* <Carousel.Item className='hero-slider'>
                    <div className='position-relative' style={{ background: '#0A0E94', height:'100vh' }}>
                        <div className="position-absolute" style={{    height: '420px', width: '850px', top: '200px',right: '180px'}}>
                        <img className="img-fluid rah position-absolute" style={{ height: '100%' }} src={sliderImg2} alt="First slide" />
                        </div>
                    </div>
                    <Carousel.Caption className='hero__content'>  <div class="col-8 col-lg-8 offset-lg-1 col-xl-6">
                        <div class="hero__content--inner">
                            <h1 class="hero-title-1 title title--large title--white text-uppercase textone">Video Conference</h1>
                            <h1 class="hero-title-2 title title--large title--white font--bold text-uppercase textone">System</h1>
                            <h6 class="hero-title-3 title title--white pos-relative texttwo">
                                Infotech is an experienced Hardware<br className='py-2'></br> outsourcing company that provides
                                <br className='py-2'></br>services for various businesses
                            </h6>
                            <NavLink to="/contact" className="col-3 mx-auto text-center btn btn-outline-light btn-danger text-uppercase d-none d-lg-block d-xl-block">
                                Contact Now
                            </NavLink>
                        </div>
                    </div>

                    </Carousel.Caption>
                </Carousel.Item> */}

            </Carousel>
        </>
    );
}

export default HeroSlider;