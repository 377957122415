import React from "react";

const PopularProduct = (prop) => {
    return (
        <>
            <div class="product__box product__box--catagory product__box--border-hover text-center pos-relative">
                <div class="product__img-box">
                    <a class="product__img--link  glossy-flash-animate">
                        <img src={prop.cardImg} alt="" class="product__img" style={{height:"200px",width:""}} />
                    </a>
                </div>
                <div class="product__content--catagory my-4 pt-3">
                    <a class="btn btn-outline-dark btn--box btn--tiny btn--gray btn--gray-hover-orange font--bold text-uppercase">{prop.cardTitle}
                    </a>
                </div>
            </div>
        </>
    )
};

const HomeHeaderContent = (prop) => {

    return (
        <>
            <div className="container mt-5">
                <div class="row d-flex align-items-center justify-content-between d-sm-column ">
                    <div class="col-md-12 col-lg-6">
                        <div class=" py-4">
                                <img src={prop.AboutImg} alt="" className="img-fluid shadow-lg border border-dark" />
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6 px-3">
                        <div class="text-align align-item">
                            <h1 className="font--semi-bold section-content__title text-justify pb-4"><span className="white-orange">{prop.HomeHeader}</span>{prop.HomeHeader1}</h1>
                            <h5 className="font--semi-bold text-justify">{prop.HomeContent}</h5>
                            <p class="para__text">{prop.HomeInfo}</p>
                        </div>
                    </div>
                </div>
            </div>

            
        </>
    );
}

export default PopularProduct;
export { HomeHeaderContent };