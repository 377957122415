

import React from "react";
import { MiddleBanner } from "../Banner/Banner";
import MiddleBannerImg1 from '../../assets/img/banner/size-extra-large-wide/banner-home-1-img-1-extra-large-wide.jpg'
import Navbar from "../../Navbar";
import ProductDetails from "./ProductDetails";
import Footer from "../../Footer";

import ServicesProduct1 from '../../assets/img/Services/Network & Solution/product-gallery-large-1.jpg';
import ServicesProduct2 from '../../assets/img/Services/Network & Solution/product-gallery-large-2.jpg';
import ServicesProduct3 from '../../assets/img/Services/Network & Solution/product-gallery-large-3.jpg';
import ServicesProduct4 from '../../assets/img/Services/Network & Solution/product-gallery-large-4.jpg';
import whatsappLogo from '../../assets/img/whatsapp.png';

const NetworkAndSolutions = () => {
    return (
        <>
            <Navbar />
            <MiddleBanner MiddleBannerImg={MiddleBannerImg1} BannerHeading="Network & Solution" />

            <div className="product-details mt-5">
                <div class="container">
                    <div class="row mt-5 pt-4">
                        <div class="col-md-5">
                            <div class="product-gallery-box m-b-60">
                                <div class="product-image--gallery overflow-hidden">
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct1} alt="" />
                                            {/* <img src="assets/img/product/gallery/gallery-large/product-gallery-large-2.jpg" alt="" /> */}
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct2} alt="" />
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct3} alt="" />
                                        </div>
                                    </div>
                                    <div class="float-left float-grid-2">
                                        <div class="img-responsive border-around">
                                            <img src={ServicesProduct4} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-5">
                            <div class="product-details-box m-b-60 px-2">
                                <h5 className="h2 m-b-20">Network & Solution</h5>

                                <div class="product__desc m-t-25 m-b-30">
                                    <p className='text-justify'>
                                        Network and solutions play a critical role in enabling organizations to establish robust and reliable network infrastructures that support their operations and business objectives. They empower efficient data transfer, seamless communication, secure access to resources, and collaboration across geographically dispersed teams. The design, implementation, and management of network and solutions require expertise in networking technologies, security practices, and industry best practices to ensure a stable and high-performing network environment.
                                    </p>
                                </div>
                                <div class="product-var p-tb-30">
                                    <div class="product__stock m-b-20">
                                        <span class="product__stock--in"><i class="fas fa-check-circle"></i> IN STOCK</span>
                                    </div>
                                    {/* <div class="product-color product-var__item">
                                        <span class="product-var__text">Color</span>
                                        <div class="sidebar__color-filter ">
                                            <label class="product-color"><input name="large-product-color" type="radio" class="product-color-select" value="color-red" /><span></span></label>
                                            <label class="product-color" ><input name="large-product-color" type="radio" class="product-color-select" value="color-green" checked /><span></span></label>
                                            <label class="product-color" ><input name="large-product-color" type="radio" class="product-color-select" value="color-blue" /><span></span></label>
                                        </div>
                                    </div> */}

                                    {/* <div class="product-links">
                                        <a href="wishlist.html" class="link--gray link--icon-left shop__wishlist-icon m-b-5 m-r-20"><i class="ion-android-favorite-outline"></i>Add To Wishlist</a>
                                        <a href="compare.html" class="link--gray link--icon-left shop__wishlist-icon m-b-5"><i class="ion-ios-loop-strong"></i>Compare</a>
                                    </div> */}
                                    <div className='col-lg-12 col-md-12 d-none d-md-block d-lg-block'>

                                        <div class="nametag p-tb-10 ">
                                            <span className=''>Product:</span>
                                            <ul class="nametag__list d-flex justify-content-between">
                                                <li>PAN,</li>
                                                <li>LAN,</li>
                                                <li>WLAN,</li>
                                                <li>Services.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="nametag p-tb-10">
                                        <span>Share This Product :</span>
                                        <ul class="nametag__list d-flex justify-content-center">
                                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                            <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product-details-tab-area">
                <div class="container">
                    <div class="row pb-5">
                        <div class="col-12">
                            <ul class="tablist tablist--style-black tablist--style-title tablist--style-gap-70 nav">
                                <li><a class="nav-link active" data-bs-toggle="tab" href="#product-desc">Description</a></li>
                            </ul>
                        </div>
                        <div class="col-md-12">
                            <div class="product-details-tab-box">
                                <div class="tab-content">
                                    {/* Start Tab - Product Description */}
                                    <div class="tab-pane show active" id="product-desc">
                                        <div class="para__content">
                                            <h6 class="para__title">Network And Solutions :</h6>
                                            <p class="para__text">
                                                <p className="para__text">Network and solutions refer to the comprehensive set of technologies, strategies, and services implemented to create, manage, and optimize computer networks for organizations. These networks are designed to facilitate efficient communication, data sharing, and resource utilization among devices, systems, and users within an organization or across multiple locations.
                                                </p>
                                                <h6 class="para__title">Key components and features of network and solutions include:</h6>
                                                <ul class="para__list px-1">
                                                    <li className="py-1 px-0">Network Infrastructure: This encompasses the physical and logical components that form the foundation of a network. It includes devices such as routers, switches, firewalls, servers, cables, and wireless access points. The network infrastructure is designed to provide connectivity, manage network traffic, and ensure data security.</li>
                                                    <li className="py-1 px-0">Network Design and Architecture: Network and solutions involve the careful planning and design of network architectures tailored to meet the specific needs and goals of an organization. This includes determining the optimal network topology, selecting appropriate network protocols, and ensuring scalability and reliability.</li>
                                                    <li className="py-1 px-0">Network Security: Network and solutions prioritize the implementation of robust security measures to protect the network infrastructure, data, and sensitive information. This involves deploying firewalls, intrusion detection and prevention systems, virtual private networks (VPNs), authentication mechanisms, and encryption protocols to safeguard against unauthorized access, data breaches, and cyber threats.</li>
                                                    <li className="py-1 px-0">Network Management: Efficient network management is crucial for maintaining network performance, troubleshooting issues, and ensuring uptime. Network and solutions incorporate network management tools and practices that enable administrators to monitor network traffic, configure devices, troubleshoot connectivity problems, and perform regular maintenance tasks.</li>
                                                    <li className="py-1 px-0">Network Optimization: Network and solutions aim to optimize network performance and efficiency. This includes techniques such as traffic shaping, quality of service (QoS) policies, load balancing, bandwidth management, and network performance monitoring. Optimization strategies ensure that network resources are effectively utilized and that critical applications receive the necessary bandwidth and priority.</li>
                                                    <li className="py-1 px-0">Cloud Integration: As organizations increasingly adopt cloud computing, network and solutions facilitate seamless integration and connectivity between on-premises networks and cloud-based services. This involves establishing secure connections, optimizing network performance for cloud-based applications, and implementing cloud security measures.</li>
                                                    <li className="py-1 px-0">Unified Communications: Network and solutions often integrate unified communications tools and technologies, enabling organizations to consolidate various communication channels, such as voice, video, messaging, and collaboration applications, into a single, cohesive platform. This promotes efficient communication and collaboration among employees, partners, and customers.</li>
                                                </ul>
                                            </p>

                                        </div>
                                        <p className="para__text">Network and solutions play a critical role in enabling organizations to establish robust and reliable network infrastructures that support their operations and business objectives. They empower efficient data transfer, seamless communication, secure access to resources, and collaboration across geographically dispersed teams. The design, implementation, and management of network and solutions require expertise in networking technologies, security practices, and industry best practices to ensure a stable and high-performing network environment.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fixed-bottom right-100 p-3" style={{ zIndex: "6", left: "initial" }}>
                <a href="https://wa.me/919004392220?text=Hello How can I help you ?" target="_blank">
                    <img src={whatsappLogo} width="60" alt="aaaa" />
                </a>
            </div>
            <Footer />
        </>
    )
};

export default NetworkAndSolutions;